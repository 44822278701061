const landmarks=[
    {"id":"1","landmark_az":"28 Mall","landmark_ru":"28 Малл","landmark_en":"28 Mall","lattitude":"40.378929","longitude":"49.846698","district_id":"4"},
    {"id":"2","landmark_az":"Gənclik Mall","landmark_ru":"Гянджлик Малл","landmark_en":"Ganjlik Mall","lattitude":"40.3994644","longitude":"49.8525132","district_id":"3"},
    {"id":"3","landmark_az":"A.S.Puşkin parkı","landmark_ru":"Парк А.С.Пушкина","landmark_en":"Park A.S.Pushkin","lattitude":"40.375085","longitude":"49.854804","district_id":"8"},
    {"id":"4","landmark_az":"ABŞ səfirliyi","landmark_ru":"Посольство США","landmark_en":"Embassy of the USA","lattitude":"40.386896","longitude":"49.8432","district_id":"4"},
    {"id":"5","landmark_az":"Absheron Marriott otel","landmark_ru":"Марриот Абшерон","landmark_en":"Marriot Absheron","lattitude":"40.374723","longitude":"49.856669","district_id":"8"},
    {"id":"6","landmark_az":"ADA universiteti","landmark_ru":"Университет ADA","landmark_en":"ADA University","lattitude":"40.395155","longitude":"49.850764","district_id":"3"},
    {"id":"7","landmark_az":"AF Business House","landmark_ru":"AF Бизнес Центр","landmark_en":"AF Business Center","lattitude":"40.377194","longitude":"49.853744","district_id":"4"},
    {"id":"8","landmark_az":"AAAF PARK","landmark_ru":"AAAF PARK","landmark_en":"AAAF PARK","lattitude":"40.462077","longitude":"49.725154","district_id":"1"},
    {"id":"9","landmark_az":"AGA Business Center","landmark_ru":"AGA Бизнес Центр","landmark_en":"AGA Business Center","lattitude":"40.383321","longitude":"49.877391","district_id":"9"},
    {"id":"10","landmark_az":"AMAY","landmark_ru":"АМАЙ","landmark_en":"AMAY","lattitude":"40.379553","longitude":"49.878448","district_id":"9"},
    {"id":"11","landmark_az":"Ambassador otel","landmark_ru":"Амбассадор Отель","landmark_en":"Ambassador Hotel","lattitude":"40.4004092","longitude":"49.831559","district_id":"3"},
    {"id":"12","landmark_az":"Araz kinoteatrı","landmark_ru":"Кинотеатр Араз","landmark_en":"Araz Cinema","lattitude":"40.37056","longitude":"49.83571","district_id":"4"},
    {"id":"13","landmark_az":"ATV telekanalı","landmark_ru":"Телеканал ATV","landmark_en":"ATV TV channel","lattitude":"40.376829","longitude":"49.804984","district_id":"11"},
    {"id":"14","landmark_az":"Milli Aviasiya Akademiyası","landmark_ru":"Академия Авиации","landmark_en":"Academy of Aviation","lattitude":"40.453432","longitude":"50.065992","district_id":"10"},
    {"id":"15","landmark_az":"Avropa otel","landmark_ru":"Отель Европа","landmark_en":"Hotel Europe","lattitude":"40.392769","longitude":"49.821984","district_id":"4"},
    {"id":"16","landmark_az":"Axundov bağı","landmark_ru":"Парк Ахундова","landmark_en":"Akhundov Park","lattitude":"40.368091","longitude":"49.829814","district_id":"8"},
    {"id":"17","landmark_az":"Aygun City","landmark_ru":"Айгюн сити","landmark_en":"Aygun City","lattitude":"40.417869","longitude":"49.965162","district_id":"12"},
    {"id":"18","landmark_az":"Ayna Sultanova heykəli","landmark_ru":"Айна Султанова памятник","landmark_en":"Ayna Sultanova Monument","lattitude":"40.413438","longitude":"49.846135","district_id":"2"},
    {"id":"19","landmark_az":"Azadlıq meydanı","landmark_ru":"Площадь Азадлыг","landmark_en":"Azadlig Square","lattitude":"40.373351","longitude":"49.853033","district_id":"8"},
    {"id":"20","landmark_az":"Azneft meydanı","landmark_ru":"Площадь Азнефть","landmark_en":"Azneft Square","lattitude":"40.362557","longitude":"49.835363","district_id":"8"},
    {"id":"21","landmark_az":"AZTV telekanalı","landmark_ru":"Телеканал AZTV","landmark_en":"AZTV TV channel","lattitude":"40.356967","longitude":"49.824387","district_id":"8"},
    {"id":"22","landmark_az":"Azərbaycan Dillər Universiteti","landmark_ru":"Университет Языков","landmark_en":"University of Languages","lattitude":"40.383638","longitude":"49.843199","district_id":"4"},
    {"id":"23","landmark_az":"Azərbaycan kinoteatrı","landmark_ru":"Кинотеатр Азербайджан","landmark_en":"Azerbaijan Cinema","lattitude":"40.368743","longitude":"49.837573","district_id":"8"},
    {"id":"24","landmark_az":"Azərbaycan Тurizm İnstitutu","landmark_ru":"Интститут Туризма","landmark_en":"Tourism Institute","lattitude":"40.397904","longitude":"49.857186","district_id":"3"},
    {"id":"25","landmark_az":"Baku Mall","landmark_ru":"Baku Mall","landmark_en":"Baku Mall","lattitude":"40.3898202","longitude":"49.8064776","district_id":"11"},
    {"id":"26","landmark_az":"Babək Plaza","landmark_ru":"Бабек Плаза","landmark_en":"Babek Plaza","lattitude":"40.388485","longitude":"49.869394","district_id":"10"},
    {"id":"27","landmark_az":"Bakı Asiya Universiteti","landmark_ru":"Университет Баку Азия","landmark_en":"Baku Asia University","lattitude":"40.401797","longitude":"49.8192","district_id":"11"},
    {"id":"28","landmark_az":"Bakı Dövlət Universiteti","landmark_ru":"БГУ","landmark_en":"Baku State University","lattitude":"40.374333","longitude":"49.810505","district_id":"11"},
    {"id":"29","landmark_az":"Bakı Musiqi Akademiyası","landmark_ru":"Академия Музыки","landmark_en":"Academy of Music","lattitude":"40.377196","longitude":"49.844235","district_id":"4"},
    {"id":"30","landmark_az":"Bakı Slavyan Universiteti","landmark_ru":"БСУ","landmark_en":"Baku Slavic University","lattitude":"40.3839037","longitude":"49.8392097","district_id":"4"},
    {"id":"31","landmark_az":"Bayıl parkı","landmark_ru":"Баильский парк","landmark_en":"Bayil Park","lattitude":"40.34353","longitude":"49.84034","district_id":"8"},
    {"id":"32","landmark_az":"Beşmərtəbə","landmark_ru":"Бешмяртябя","landmark_en":"Beshmertebe","lattitude":"40.375895","longitude":"49.832367","district_id":"8"},
    {"id":"33","landmark_az":"Binə ticarət mərkəzi","landmark_ru":"Торговый центр БИНЯ","landmark_en":"Bina Shopping Center","lattitude":"40.303036","longitude":"49.754163","district_id":"6"},
    {"id":"34","landmark_az":"Botanika bağı","landmark_ru":"Ботанический сад","landmark_en":"Botanical Garden","lattitude":"40.35679","longitude":"49.812763","district_id":"8"},
    {"id":"35","landmark_az":"Bridge Plaza","landmark_ru":"Bridge Плаза","landmark_en":"Bridge Plaza","lattitude":"40.386899","longitude":"49.829263","district_id":"4"},
    {"id":"36","landmark_az":"Caspian Plaza","landmark_ru":"Каспиан Плаза","landmark_en":"Caspian Plaza","lattitude":"40.3853444","longitude":"49.8264935","district_id":"4"},
    {"id":"37","landmark_az":"Caspian Shopping Center","landmark_ru":"Каспиан Шоп","landmark_en":"Caspian Shopping Center","lattitude":"40.400839","longitude":"49.852489","district_id":"3"},
    {"id":"38","landmark_az":"Cavanşir körpüsü","landmark_ru":"Мост Джаваншира","landmark_en":"Javanshir Bridge","lattitude":"40.3774131","longitude":"49.860868","district_id":"9"},
    {"id":"39","landmark_az":"Çıraq Plaza","landmark_ru":"Чыраг Плаза","landmark_en":"Chirag Plaza","lattitude":"40.3802866","longitude":"49.8645498","district_id":"9"},
    {"id":"40","landmark_az":"Crystal Plaza","landmark_ru":"Кристал Плаза","landmark_en":"Crystal Plaza","lattitude":"40.384587","longitude":"49.86287","district_id":"9"},
    {"id":"41","landmark_az":"Ceyranbatan gölü","landmark_ru":"озеро Джейранбатан","landmark_en":"Jeyranbatan Lake","lattitude":"40.5250843","longitude":"49.6617932","district_id":"1"},
    {"id":"42","landmark_az":"Dağüstü parkı","landmark_ru":"Нагорный парк","landmark_en":"Nagorno Park","lattitude":"40.357392","longitude":"49.830722","district_id":"8"},
    {"id":"43","landmark_az":"Dalğa Plaza","landmark_ru":"Далга Плаза","landmark_en":"Dalga Plaza","lattitude":"40.357851","longitude":"49.834661","district_id":"8"},
    {"id":"44","landmark_az":"Daxili İşlər Nazirliyi","landmark_ru":"МВД","landmark_en":"Ministry of İnternal Affairs","lattitude":"40.370938","longitude":"49.833864","district_id":"8"},
    {"id":"45","landmark_az":"Dostluq kinoteatrı","landmark_ru":"Кинотеатр Дружба","landmark_en":"Drujba Cinema","lattitude":"40.389967","longitude":"49.842353","district_id":"4"},
    {"id":"46","landmark_az":"Dövlət İdarəçilik Akademiyası","landmark_ru":"Академия Управления при Президенте","landmark_en":"Presidential Academy","lattitude":"40.3661818","longitude":"49.8285653","district_id":"8"},
    {"id":"47","landmark_az":"Dövlət Statistika Komitəsi","landmark_ru":"Статистика","landmark_en":"Statistics Commitee","lattitude":"40.3831984","longitude":"49.820052","district_id":"11"},
    {"id":"48","landmark_az":"Dədə Qorqud parkı","landmark_ru":"Парк Дядя Горгуд","landmark_en":"Dede Gorgud Park","lattitude":"40.39229","longitude":"49.846877","district_id":"3"},
    {"id":"49","landmark_az":"Dəmirçi Plaza","landmark_ru":"Дямирчи Плаза","landmark_en":"Damirchi Plaza","lattitude":"40.382965","longitude":"49.872128","district_id":"9"},
    {"id":"50","landmark_az":"Dənizkənarı Milli park","landmark_ru":"Бульвар","landmark_en":"Boulevard","lattitude":"40.371159","longitude":"49.849477","district_id":"8"},
    {"id":"51","landmark_az":"Elmlər Akademiyası","landmark_ru":"Академия Наук","landmark_en":"Academy of Sciences","lattitude":"40.373263","longitude":"49.813655","district_id":"11"},
    {"id":"52","landmark_az":"Ekologiya və Təbii Sərvətlər Nazirliyi","landmark_ru":"Министерство Экологии","landmark_en":"Ministry of Ecology","lattitude":"40.373613","longitude":"49.819102","district_id":"11"},
    {"id":"53","landmark_az":"Elit ticarət mərkəzi","landmark_ru":"Торговый центр Элит","landmark_en":"Elite Shopping Center","lattitude":"40.4197811","longitude":"49.8520913","district_id":"2"},
    {"id":"54","landmark_az":"Fairmont otel","landmark_ru":"Отель Фаирмонт","landmark_en":"Fairmont Hotel","lattitude":"40.3598612","longitude":"49.822978","district_id":"8"},
    {"id":"55","landmark_az":"Ədliyyə Nazirliyi","landmark_ru":"Министерство Юстиции","landmark_en":"Ministry of Justice","lattitude":"40.3745441","longitude":"49.8158294","district_id":"11"},
    {"id":"56","landmark_az":"Əmək və Əhalinin Sosial Müdafiəsi Nazirliyi","landmark_ru":"Министерство Труда","landmark_en":"The Ministry of Labour","lattitude":"40.379595","longitude":"49.830967","district_id":"4"},
    {"id":"57","landmark_az":"Fontanlar bağı","landmark_ru":"Парк Фонтанов","landmark_en":"Fountains Park","lattitude":"40.370816","longitude":"49.836685","district_id":"8"},
    {"id":"58","landmark_az":"Four Seasons otel","landmark_ru":"отель Four Seasons","landmark_en":"Four Seasons Hotel","lattitude":"40.3636112","longitude":"49.8346353","district_id":"8"},
    {"id":"59","landmark_az":"Fransa Səfirliyi","landmark_ru":"Французское Посольство","landmark_en":"French Embassy","lattitude":"40.3705734","longitude":"49.8381831","district_id":"8"},
    {"id":"60","landmark_az":"Fövqəladə Hallar Nazirliyi","landmark_ru":"МЧС","landmark_en":"Ministry of Emergency Situations","lattitude":"40.363406","longitude":"49.817207","district_id":"8"},
    {"id":"61","landmark_az":"Gənclər və İdman Nazirliyi","landmark_ru":"Министерство Спорта","landmark_en":"Ministry of Sports","lattitude":"40.3992808","longitude":"49.8530796","district_id":"3"},
    {"id":"62","landmark_az":"Heydər Əliyev Sarayı","landmark_ru":"Дворец им. Г.Алиева","landmark_en":"Heydar Aliyev Palace","lattitude":"40.378026","longitude":"49.8251787","district_id":"4"},
    {"id":"63","landmark_az":"Heydər Əliyev Mərkəzi","landmark_ru":"Центр Гейдара Алиева","landmark_en":"Heydar Aliyev Center","lattitude":"40.396029","longitude":"49.868706","district_id":"3"},
    {"id":"64","landmark_az":"Hilton otel","landmark_ru":"отель Хильтон","landmark_en":"Hilton Hotel","lattitude":"40.372022","longitude":"49.850139","district_id":"8"},
    {"id":"65","landmark_az":"Hüseyn Cavid parkı","landmark_ru":"Парк Г.Джавида","landmark_en":"G.Javid Park","lattitude":"40.373396","longitude":"49.817449","district_id":"11"},
    {"id":"66","landmark_az":"Hyatt Regency","landmark_ru":"Hyatt Regency","landmark_en":"Hyatt Regency","lattitude":"40.3877925","longitude":"49.8222089","district_id":"4"},
    {"id":"67","landmark_az":"Hərbi Hospital","landmark_ru":"Военный Госпиталь","landmark_en":"Military hospital","lattitude":"40.400917","longitude":"49.824393","district_id":"4"},
    {"id":"68","landmark_az":"Xəzər Universiteti","landmark_ru":"Университет Хазар","landmark_en":"University of Khazar","lattitude":"40.408323","longitude":"49.937638","district_id":"11"},
    {"id":"69","landmark_az":"Xalça Muzeyi","landmark_ru":"Музей Халча","landmark_en":"Carpet Museum","lattitude":"40.360289","longitude":"49.835459","district_id":"8"},
    {"id":"70","landmark_az":"Xarici İşlər Nazirliyi","landmark_ru":"МИД","landmark_en":"Ministry of Foreign Affairs","lattitude":"40.377432","longitude":"49.832277","district_id":"8"},
    {"id":"71","landmark_az":"Xəqani ticarət mərkəzi","landmark_ru":"Торговый центр Хягани","landmark_en":"Khagani Shopping Center","lattitude":"40.3719968","longitude":"49.8392087","district_id":"8"},
    {"id":"72","landmark_az":"İctimai Televiziya","landmark_ru":"İTV","landmark_en":"ITV","lattitude":"40.401562","longitude":"49.801538","district_id":"11"},
    {"id":"73","landmark_az":"İçəri Şəhər","landmark_ru":"Ичяри Шяхяр","landmark_en":"Icherisheher","lattitude":"40.367257","longitude":"49.834743","district_id":"8"},
    {"id":"74","landmark_az":"H.Əliyev adına İdman sarayı","landmark_ru":"Комплекс Спорта","landmark_en":"Sports Complex","lattitude":"40.3919623","longitude":"49.8228612","district_id":"4"},
    {"id":"75","landmark_az":"İncəsənət və Mədəniyyət Universiteti","landmark_ru":"Университет Искусства и Культуры","landmark_en":"University of Art and Culture","lattitude":"40.380192","longitude":"49.820498","district_id":"11"},
    {"id":"76","landmark_az":"İnşaat Universiteti","landmark_ru":"Университет Иншаат","landmark_en":" Inshaat University","lattitude":"40.3699365","longitude":"49.8133736","district_id":"11"},
    {"id":"77","landmark_az":"İqtisadiyyat Universiteti","landmark_ru":"Университет Экономики","landmark_en":"University of Economy","lattitude":"40.36761","longitude":"49.831951","district_id":"8"},
    {"id":"78","landmark_az":"ISR Plaza","landmark_ru":"ISR Плаза","landmark_en":"ISR Plaza","lattitude":"40.3724148","longitude":"49.8353393","district_id":"8"},
    {"id":"79","landmark_az":"İzmir parkı","landmark_ru":"Парк Измир","landmark_en":"Izmir Park","lattitude":"40.38523","longitude":"49.823498","district_id":"11"},
    {"id":"80","landmark_az":"İtaliya Səfirliyi","landmark_ru":"Итальянское Посольство","landmark_en":"Italian Embassy","lattitude":"40.3660659","longitude":"49.8300145","district_id":"8"},
    {"id":"81","landmark_az":"Keşlə bazarı","landmark_ru":"Базар Кешля","landmark_en":"Keshle Bazaar","lattitude":"40.412195","longitude":"49.871882","district_id":"3"},
    {"id":"82","landmark_az":"Koala parkı","landmark_ru":"Коала Парк","landmark_en":"Koala Park","lattitude":"40.391484","longitude":"49.840936","district_id":"3"},
    {"id":"83","landmark_az":"Kaktus restoranı","landmark_ru":"Кактус ресторан","landmark_en":"Cactus restaurant","lattitude":"40.374351","longitude":"49.804815","district_id":"11"},
    {"id":"84","landmark_az":"Kooperasiya Universiteti","landmark_ru":"Университет Кооперации","landmark_en":"University of Cooperation","lattitude":"40.409608","longitude":"49.837664","district_id":"3"},
    {"id":"85","landmark_az":"Landmark","landmark_ru":"Ландмарк","landmark_en":"Landmark","lattitude":"40.375408","longitude":"49.849872","district_id":"8"},
    {"id":"86","landmark_az":"Lider telekanalı","landmark_ru":"Телеканал Lider","landmark_en":"Lider TV channel","lattitude":"40.3865676","longitude":"49.8130527","district_id":"11"},
    {"id":"87","landmark_az":"Leyla Şıxlinskaya xəstəxanası","landmark_ru":"Больница им. Лейли Шыхлинской","landmark_en":"Leyla Shchlinskaya Hospital","lattitude":"40.381972","longitude":"49.86307","district_id":"9"},
    {"id":"88","landmark_az":"M.Hüseynzadə parkı","landmark_ru":"Парк М.Гусейнзаде","landmark_en":"M.Guseynzade Park","lattitude":"40.3880782","longitude":"49.7612751","district_id":"4"},
    {"id":"89","landmark_az":"Maliyyə Nazirliyi","landmark_ru":"Министерство Финансов","landmark_en":"Ministry of Finance","lattitude":"40.3873893","longitude":"49.8337821","district_id":"4"},
    {"id":"90","landmark_az":"Malakan bağı","landmark_ru":"Парк Малакан","landmark_en":"Malakan Park ","lattitude":"40.371619","longitude":"49.840431","district_id":"8"},
    {"id":"91","landmark_az":"Metropark","landmark_ru":"Метропарк","landmark_en":"Metropark","lattitude":"40.4033907","longitude":"49.870667","district_id":"3"},
    {"id":"92","landmark_az":"Milli Konservatoriya","landmark_ru":"Национальная Консерватория","landmark_en":"National Conservatory","lattitude":"40.365404","longitude":"49.818163","district_id":"4"},
    {"id":"93","landmark_az":"Dövlət Təhlükəsizliyi Xidməti","landmark_ru":"Служба Гос Безопасности","landmark_en":"State Security Service","lattitude":"40.361636","longitude":"49.822821","district_id":"11"},
    {"id":"94","landmark_az":"Moskva univermağı","landmark_ru":"Московский Универмаг","landmark_en":"Moscow department store","lattitude":"40.4093961","longitude":"49.8076748","district_id":"4"},
    {"id":"95","landmark_az":"MUM","landmark_ru":"МУМ","landmark_en":"MUM","lattitude":"40.371021","longitude":"49.834328","district_id":"8"},
    {"id":"96","landmark_az":"Musabəyov parkı","landmark_ru":"Парк Мусабекова","landmark_en":"Musabekov Park","lattitude":"40.3797358","longitude":"49.8056872","district_id":"11"},
    {"id":"97","landmark_az":"Monte Kristo restoran","landmark_ru":"Monte Kristo restoran","landmark_en":"Monte Kristo restaurant","lattitude":"40.4173953","longitude":"49.8376463","district_id":"2"},
    {"id":"98","landmark_az":"Nazlı şadlıq sarayı","landmark_ru":"Дом торжеств Назлы ","landmark_en":"Nazli restaurant","lattitude":"40.408601","longitude":"49.9551654","district_id":"5"},
    {"id":"99","landmark_az":"Nərimanov rayon Məhkəməsi","landmark_ru":"Наримановский Суд","landmark_en":"Narimanov court","lattitude":"40.397834","longitude":"49.87838","district_id":"3"},
    {"id":"100","landmark_az":"Nargiz ticarət mərkəzi","landmark_ru":"Торговый Центр Наргиз","landmark_en":"Nargiz Shopping Center","lattitude":"40.3718405","longitude":"49.8352473","district_id":"8"},
    {"id":"101","landmark_az":"Neapol dairəsi","landmark_ru":"Круг Неапола","landmark_en":"Neapol Circle","lattitude":"40.382473","longitude":"49.964369","district_id":"9"},
    {"id":"102","landmark_az":"Neft Akademiyası","landmark_ru":"Нефтяная Академия","landmark_en":"Neft Academy","lattitude":"40.377952","longitude":"49.847867","district_id":"4"},
    {"id":"103","landmark_az":"Neftçi bazası","landmark_ru":"Нефтчи база","landmark_en":"Neftchi Baza","lattitude":"40.402663","longitude":"49.838112","district_id":"3"},
    {"id":"104","landmark_az":"Nizami kinoteatrı","landmark_ru":"Кинотеатр Низами","landmark_en":"Nizami Cinema ","lattitude":"40.375399","longitude":"49.844207","district_id":"4"},
    {"id":"105","landmark_az":"Nəqliyyat Nazirliyi","landmark_ru":"Министерство Транспорта","landmark_en":"Ministry of Transportation","lattitude":"40.3956585","longitude":"49.8140785","district_id":"11"},
    {"id":"106","landmark_az":"Nəriman Nərimanov parkı","landmark_ru":"Наримановский Парк","landmark_en":"Narimanov Park","lattitude":"40.402002","longitude":"49.871628","district_id":"3"},
    {"id":"107","landmark_az":"Nərimanov heykəli","landmark_ru":"Памятник Нариманова","landmark_en":"Narimanov Monument","lattitude":"40.368506","longitude":"49.824273","district_id":"11"},
    {"id":"108","landmark_az":"Nəsimi bazarı","landmark_ru":"Насиминский базар","landmark_en":"Nasimi Bazaar","lattitude":"40.3961","longitude":"49.84073","district_id":"4"},
    {"id":"109","landmark_az":"Nəsimi heykəli","landmark_ru":"Памятник Насими","landmark_en":"Nasimi Monument","lattitude":"40.373821","longitude":"49.842393","district_id":"8"},
    {"id":"110","landmark_az":"Oazis restoran","landmark_ru":"Oazis ресторан","landmark_en":"OAZIS Restaurant","lattitude":"40.419538","longitude":"49.846687","district_id":"2"},
    {"id":"111","landmark_az":"Odlar Yurdu Universiteti","landmark_ru":"Университет Одлар Юрду","landmark_en":"Odlar Yurdu University","lattitude":"40.397623","longitude":"49.857972","district_id":"3"},
    {"id":"112","landmark_az":"Olimp","landmark_ru":"Олимп","landmark_en":"Olymp","lattitude":"40.375823","longitude":"49.819785","district_id":"11"},
    {"id":"113","landmark_az":"Olimpia Stadionu","landmark_ru":"Стадион Олимпиа","landmark_en":"Olympia Stadium","lattitude":"40.429191","longitude":"49.919483","district_id":"7"},
    {"id":"114","landmark_az":"Oksford məktəbi","landmark_ru":"шк. Оксфорд","landmark_en":"Oxford school","lattitude":"40.354357","longitude":"49.818468","district_id":"8"},
    {"id":"115","landmark_az":"Olimpik Star","landmark_ru":"Олимпик Стар","landmark_en":"Olympic Star","lattitude":"40.4017046","longitude":"49.8304603","district_id":"3"},
    {"id":"116","landmark_az":"Park Bulvar","landmark_ru":"Парк Бульвар","landmark_en":"Park Boulevard","lattitude":"40.371141","longitude":"49.849506","district_id":"8"},
    {"id":"117","landmark_az":"Park Inn","landmark_ru":"Парк Инн","landmark_en":"Park Inn","lattitude":"40.37155142","longitude":"49.848971","district_id":"8"},
    {"id":"118","landmark_az":"Park Zorge","landmark_ru":"Парк Зорге","landmark_en":"Zorge Park","lattitude":"40.39057866","longitude":"49.83723484","district_id":"3"},
    {"id":"119","landmark_az":"Port Baku","landmark_ru":"Порт Баку","landmark_en":"Port Baku","lattitude":"40.37533072","longitude":"49.85995058","district_id":"8"},
    {"id":"120","landmark_az":"Prezident parkı","landmark_ru":"Президентский парк","landmark_en":"Presidential Park","lattitude":"40.36476338","longitude":"49.82954603","district_id":"8"},
    {"id":"121","landmark_az":"Qız Qalası","landmark_ru":"Девичья Башня","landmark_en":"Maiden Tower","lattitude":"40.36601675","longitude":"49.83738983","district_id":"8"},
    {"id":"122","landmark_az":"Qafqaz Universiteti","landmark_ru":"Университет Кавказ","landmark_en":"University of Caucasus","lattitude":"40.47585972","longitude":"49.725434","district_id":"1"},
    {"id":"123","landmark_az":"Qış parkı","landmark_ru":"Зимний парк","landmark_en":"Winter Park","lattitude":"40.37723961","longitude":"49.83959221","district_id":"4"},
    {"id":"124","landmark_az":"Qələbə dairəsi","landmark_ru":"Круг Гялябя","landmark_en":"Galyabya Circle","lattitude":"40.39007658","longitude":"49.80757671","district_id":"11"},
    {"id":"125","landmark_az":"Qərb Universiteti","landmark_ru":"Западный Университет","landmark_en":"Western University","lattitude":"40.36441984","longitude":"49.8307823","district_id":"8"},
    {"id":"126","landmark_az":"Rabitə və Yüksək Texnologiyalar Nazirliyi","landmark_ru":"Министерство связи и инф. технологий","landmark_en":"Ministry of Communications and Inf. Technology","lattitude":"40.37176648","longitude":"49.84592166","district_id":"8"},
    {"id":"127","landmark_az":"Rusiya səfirliyi","landmark_ru":"Посольство РФ","landmark_en":"Embassy of the Russian Federation","lattitude":"40.38963911","longitude":"49.83275872","district_id":"4"},
    {"id":"128","landmark_az":"Rəssamlıq Akademiyası","landmark_ru":"Художественная Академия","landmark_en":"Art Academy","lattitude":"40.39624203","longitude":"49.87301315","district_id":"8"},
    {"id":"129","landmark_az":"Su İdmanı sarayı","landmark_ru":"Комплекс Водного Спорта","landmark_en":"Water Sports Complex","lattitude":"40.33461804","longitude":"49.83506712","district_id":"8"},
    {"id":"130","landmark_az":"Sahil bağı","landmark_ru":"Парк Сахиль","landmark_en":"Sahil Park","lattitude":"40.37298393","longitude":"49.8453858","district_id":"8"},
    {"id":"131","landmark_az":"Sevil Qazıyeva parkı","landmark_ru":"Парк С.Газиева","landmark_en":"S.Gaziyeva Park","lattitude":"40.38842368","longitude":"49.83224193","district_id":"4"},
    {"id":"132","landmark_az":"Bakı Dövlət Sirki","landmark_ru":"Цирк","landmark_en":"Baku circus","lattitude":"40.38313007","longitude":"49.83951911","district_id":"4"},
    {"id":"133","landmark_az":"Sea Breeze","landmark_ru":"Sea Breeze","landmark_en":"Sea Breeze","lattitude":"40.59186128","longitude":"49.98710281","district_id":"10"},
    {"id":"134","landmark_az":"Sovetski","landmark_ru":"Советски","landmark_en":"Sovetski","lattitude":"40.37048396","longitude":"49.83160487","district_id":"8"},
    {"id":"135","landmark_az":"Space TV","landmark_ru":"Телеканал Space","landmark_en":"Space TV channel","lattitude":"40.36652342","longitude":"49.81761486","district_id":"11"},
    {"id":"136","landmark_az":"Şüvəlan Park ticarət mərkəzi","landmark_ru":"Торговый Центр Шувялан Парк","landmark_en":"Shuvalan Park Shopping Center","lattitude":"40.48426292","longitude":"50.18406491","district_id":"10"},
    {"id":"137","landmark_az":"Sədərək ticarət mərkəzi","landmark_ru":"Торговый Центр Сядяряк","landmark_en":"Sadarak Shopping center","lattitude":"40.3213055","longitude":"49.77693382","district_id":"6"},
    {"id":"138","landmark_az":"Şəfa stadionu","landmark_ru":"Стадион Шяфа","landmark_en":"Shafa Stadium","lattitude":"40.41099025","longitude":"49.89765997","district_id":"3"},
    {"id":"139","landmark_az":"Şəhidlər xiyabanı","landmark_ru":"Аллея мучеников","landmark_en":"Alley of Martyrs","lattitude":"40.35650531","longitude":"49.82843282","district_id":"8"},
    {"id":"140","landmark_az":"Səhiyyə Nazirliyi","landmark_ru":"Министерство Здоровья","landmark_en":"Ministry of Health","lattitude":"40.39162442","longitude":"49.83246717","district_id":"4"},
    {"id":"141","landmark_az":"Şəlalə parkı","landmark_ru":"Парк Шялаля","landmark_en":"Shalalya Park","lattitude":"40.3634722","longitude":"49.82790832","district_id":"8"},
    {"id":"142","landmark_az":"Səməd Vurğun parkı","landmark_ru":"Парк С.Вургуна","landmark_en":"S.Vurgun Park","lattitude":"40.37892338","longitude":"49.85329715","district_id":"4"},
    {"id":"143","landmark_az":"Şərq Bazarı","landmark_ru":"Шярг Базары","landmark_en":"Sharg Bazaar","lattitude":"40.38703206","longitude":"49.85560301","district_id":"3"},
    {"id":"144","landmark_az":"Texniki Universiteti","landmark_ru":"Технический Университет","landmark_en":"Technical University","lattitude":"40.37005154","longitude":"49.81525268","district_id":"11"},
    {"id":"145","landmark_az":"Tibb Universiteti","landmark_ru":"Медицинский Университет","landmark_en":"Medical University","lattitude":"40.39583665","longitude":"49.83296666","district_id":"3"},
    {"id":"146","landmark_az":"Tofiq Bəhramov stadionu","landmark_ru":"Стадион им. Т.Бахрамова","landmark_en":"T. Bahramov Stadium","lattitude":"40.39732322","longitude":"49.85212382","district_id":"3"},
    {"id":"147","landmark_az":"Türkiyə səfirliyi","landmark_ru":"Посольство Турции","landmark_en":"Embassy of Turkey","lattitude":"40.38866697","longitude":"49.83711734","district_id":"4"},
    {"id":"148","landmark_az":"Təfəkkür Universiteti","landmark_ru":"Университет Тяфяккюр","landmark_en":"Tefekkur University","lattitude":"40.38876427","longitude":"49.85221218","district_id":"3"},
    {"id":"149","landmark_az":"Təhsil Nazirliyi","landmark_ru":"Министерство Образование","landmark_en":"Ministry of Education","lattitude":"40.39157297","longitude":"49.86169164","district_id":"3"},
    {"id":"150","landmark_az":"Ukrayna dairəsi","landmark_ru":"Украинский круг","landmark_en":"Ukrainian circle","lattitude":"40.37888998","longitude":"49.95396783","district_id":"9"},
    {"id":"151","landmark_az":"Vergilər Nazirliyi","landmark_ru":"Министерство Налогов","landmark_en":"Ministry of Taxes","lattitude":"40.37244508","longitude":"49.81826564","district_id":"11"},
    {"id":"152","landmark_az":"Yasamal bazarı","landmark_ru":"Ясамальский базар","landmark_en":"Yasamal Bazaar","lattitude":"40.3793786","longitude":"49.80384668","district_id":"11"},
    {"id":"153","landmark_az":"Yasamal parkı","landmark_ru":"Ясамальский парк","landmark_en":"Yasamal Park","lattitude":"40.38716781","longitude":"49.81228496","district_id":"11"},
    {"id":"154","landmark_az":"Yaşıl bazar","landmark_ru":"Зеленый базар","landmark_en":"Green Bazaar","lattitude":"40.38545619","longitude":"49.85424982","district_id":"3"},
    {"id":"155","landmark_az":"Yeganə şadlıq sarayı","landmark_ru":"Дом Торжеств \"Yeganə\"","landmark_en":"Yegane restaurant","lattitude":"40.41236339","longitude":"49.82272685","district_id":"2"},
    {"id":"156","landmark_az":"Zabitlər parkı","landmark_ru":"Парк офицеров","landmark_en":"Officers Park","lattitude":"40.3852006","longitude":"49.83631761","district_id":"4"},
    {"id":"157","landmark_az":"Zoopark","landmark_ru":"Зоопарк","landmark_en":"Baku Zoo","lattitude":"40.39198579","longitude":"49.84925618","district_id":"3"},
    {"id":"158","landmark_az":"Zərifə Əliyeva adına park","landmark_ru":"Парк им. З.Алиевы","landmark_en":"Z.Alieva Park","lattitude":"40.42265426","longitude":"49.82041781","district_id":"2"},
    {"id":"159","landmark_az":"Zərifə Əliyeva adına Göz klinikası","landmark_ru":"Клиника им. Зарифы Алиевы","landmark_en":"Zarifa Aliyeva Clinic","lattitude":"40.42098803","longitude":"49.82039635","district_id":"2"},
    {"id":"160","landmark_az":"World Business Center","landmark_ru":"Бизнес Центр World","landmark_en":"World Business Center","lattitude":"40.37852902","longitude":"49.839868","district_id":"4"},
    {"id":"161","landmark_az":"Novxanı Dalga T\/M","landmark_ru":"Novxanı Dalga T\/M","landmark_en":"Novxanı Dalga T\/M","lattitude":"40.52607847","longitude":"49.78798347","district_id":"1"},
    {"id":"162","landmark_az":"Abu Petrol Novxanı","landmark_ru":"Abu Petrol Новханы","landmark_en":"Abu Petrol Novkhana","lattitude":"40.55148086","longitude":"49.78030162","district_id":"1"},
    {"id":"163","landmark_az":"Bravo Masazır","landmark_ru":"Bravo Масазыр","landmark_en":"Bravo Masazir","lattitude":"40.48397569","longitude":"49.75798786","district_id":"1"},
    {"id":"164","landmark_az":"Movida Plaza","landmark_ru":"Movida Plaza","landmark_en":"Movida Plaza","lattitude":"40.37885516","longitude":"49.96088887","district_id":"9"},
    {"id":"165","landmark_az":"Ali Məhkəmə","landmark_ru":"Верховный Суд","landmark_en":"Supreme Court","lattitude":"40.38111907","longitude":"49.86498252","district_id":"9"},
    {"id":"166","landmark_az":"Milli Məclis","landmark_ru":"Милли Меджлис","landmark_en":"Milli Majlis","lattitude":"40.35843023","longitude":"49.82553239","district_id":"8"},
    {"id":"167","landmark_az":"Sərhədçi İdman Kompleksi","landmark_ru":"Спортивный комплекс Серхедчи","landmark_en":"Serhedchi Sports complex","lattitude":"40.39397366","longitude":"49.95568227","district_id":"9"},
    {"id":"168","landmark_az":"Güvən Klinikasi","landmark_ru":"Клиника Гювен","landmark_en":"Guven Clinic","lattitude":"40.41660052","longitude":"49.84461388","district_id":"2"},
    {"id":"169","landmark_az":"Qoşazapravka","landmark_ru":"Гошазаправка","landmark_en":"Goshazapravka","lattitude":"40.4161746","longitude":"49.837146","district_id":"2"},
    {"id":"170","landmark_az":"20 nömrəli məktəb","landmark_ru":"Школа номер 20","landmark_en":"School number 20","lattitude":"40.36339887","longitude":"49.81892516","district_id":"8"},
    {"id":"171","landmark_az":"Tennis Akademiyası","landmark_ru":"Теннисная Академия","landmark_en":"Tennis Academy","lattitude":"40.42256151","longitude":"49.84244071","district_id":"2"},
    {"id":"172","landmark_az":"Mərkəzi Bank","landmark_ru":"Центральный Банк","landmark_en":"Central Bank","lattitude":"40.3786314","longitude":"49.8454612","district_id":"4"},
    {"id":"173","landmark_az":"N.Tusi klinikası","landmark_ru":"клиника Н.Туси","landmark_en":"N.Tusi Clinic","lattitude":"40.39775536","longitude":"49.80527089","district_id":"11"},
    {"id":"174","landmark_az":"Anadolu Türk Liseyi","landmark_ru":"Турецкий Лицей Анадолу","landmark_en":"Anadolu turkish lyceum ","lattitude":"40.38095761","longitude":"49.83407997","district_id":"4"},
    {"id":"175","landmark_az":"Korrupsiya İdarəsi","landmark_ru":"Управление Коррупции","landmark_en":"Office against Corruption","lattitude":"40.4143514","longitude":"49.84290056","district_id":"2"},
    {"id":"176","landmark_az":"Atatürk parkı","landmark_ru":"Парк Ататюрк","landmark_en":"Ataturk Park","lattitude":"40.40189878","longitude":"49.85068017","district_id":"3"},
    {"id":"177","landmark_az":"Fresco Market (Xırdalan)","landmark_ru":"Фреско Маркет (Хырдалан)","landmark_en":"Fresco Market (Khirdalan)","lattitude":"40.45412164","longitude":"49.73907812","district_id":"1"},
    {"id":"178","landmark_az":"Azinko MTK","landmark_ru":"Азинко","landmark_en":"Azinko","lattitude":"40.4067876","longitude":"49.8753142","district_id":"3"},
    {"id":"179","landmark_az":"Koroğlu parkı","landmark_ru":"Парк Короглу","landmark_en":"Koroglu Park","lattitude":"40.39205669","longitude":"49.84370062","district_id":"3"},
    {"id":"180","landmark_az":"Neftçilər xəstəxanası","landmark_ru":"больница Нефтяников","landmark_en":"Neftchiler Hospital","lattitude":"40.38092404","longitude":"49.86379004","district_id":"9"},
    {"id":"181","landmark_az":"5 nömrəli xəstəxana","landmark_ru":"5-ая больница","landmark_en":"5th hospital","lattitude":"40.40338003","longitude":"49.86189826","district_id":"3"},
    {"id":"182","landmark_az":"Bakı kinoteatrı","landmark_ru":"кинотеатр Бакы","landmark_en":"Baku Cinema","lattitude":"40.38614315","longitude":"49.95967138","district_id":"9"},
    {"id":"183","landmark_az":"Hökümət evi","landmark_ru":"Дом Правительства","landmark_en":"Government House","lattitude":"40.37376468","longitude":"49.8526741","district_id":"8"},
    {"id":"184","landmark_az":"Lotos Ticarət mərkəzi","landmark_ru":"ТЦ Лотос","landmark_en":"Lotos TC","lattitude":"40.40511878","longitude":"49.94756906","district_id":"5"},
    {"id":"185","landmark_az":"Dəniz Vağzalı","landmark_ru":"Морвокзал","landmark_en":"Morvokzal","lattitude":"40.3731894","longitude":"49.85684225","district_id":"8"},
    {"id":"186","landmark_az":"Kukla Teatrı","landmark_ru":"Кукольный Театр","landmark_en":"Puppet theatre","lattitude":"40.36708186","longitude":"49.83907354","district_id":"8"},
    {"id":"187","landmark_az":"Qızıl Tac restoranı","landmark_ru":"ресторан Qızıl Tac","landmark_en":"Kizil Taj restaurant","lattitude":"40.41275492","longitude":"49.80854107","district_id":"4"},
    {"id":"188","landmark_az":"Funda Tibb Mərkəzi","landmark_ru":"Фунда","landmark_en":"Funda clinic","lattitude":"40.39419182","longitude":"49.87214454","district_id":"3"},
    {"id":"189","landmark_az":"Avromed","landmark_ru":"Евромед","landmark_en":"Evromed","lattitude":"40.41216975","longitude":"49.81437146","district_id":"9"},
    {"id":"190","landmark_az":"Megafun","landmark_ru":"Мегафан","landmark_en":"Megafun","lattitude":"40.37826648","longitude":"49.87457583","district_id":"9"},
    {"id":"191","landmark_az":"Nizami Moll","landmark_ru":"Низами Молл","landmark_en":"Nizami Mall","lattitude":"40.3787754","longitude":"49.8305941","district_id":"4"},
    {"id":"192","landmark_az":"Afrodita şadlıq sarayı","landmark_ru":"Дом торжеств Афродита","landmark_en":"Aphrodita restaurant","lattitude":"40.37389153","longitude":"49.96440408","district_id":"12"},
    {"id":"193","landmark_az":"Bülbülə şadlıq sarayı","landmark_ru":"Дом торжеств Бюлбюле","landmark_en":"Bulbul restaurant","lattitude":"40.43948966","longitude":"49.96506727","district_id":"12"},
    {"id":"194","landmark_az":"Elçin şadlıq sarayı","landmark_ru":"Дом торжеств Елчин","landmark_en":"Elchin restaurant","lattitude":"40.420888","longitude":"50.00146905","district_id":"12"},
    {"id":"195","landmark_az":"Ay işığı sadlıq sarayı","landmark_ru":"Дом торжеств Ай Ишыгы","landmark_en":"Ay Ishigi restaurant","lattitude":"40.40540844","longitude":"49.93009321","district_id":"5"},
    {"id":"196","landmark_az":"Lido restoran","landmark_ru":"Ресторан Лидо","landmark_en":"Lido restaurant","lattitude":"40.41813838","longitude":"49.84543954","district_id":"2"},
    {"id":"197","landmark_az":"Anadolu restoranı","landmark_ru":"Ресторан Анадолу","landmark_en":"Anadolu restaurant","lattitude":"40.37554978","longitude":"49.85355375","district_id":"8"},
    {"id":"198","landmark_az":"Neolit","landmark_ru":"Ресторан Неолит","landmark_en":"Neolit restaurant","lattitude":"40.40720418","longitude":"49.92766955","district_id":"5"},
    {"id":"199","landmark_az":"Astoriya","landmark_ru":"Астория","landmark_en":"Astoria","lattitude":"40.39475137","longitude":"49.93415287","district_id":"5"},
    {"id":"200","landmark_az":"Təbrik şadlıq sarayı","landmark_ru":"Дом торжеств Тебрик","landmark_en":"Tebrik restaurant","lattitude":"40.38119909","longitude":"49.95323105","district_id":"9"},
    {"id":"201","landmark_az":"Mübarək şadlıq sarayı","landmark_ru":"Дом торжеств Мубарек","landmark_en":"Mubarek restaurant","lattitude":"40.38239237","longitude":"49.8670908","district_id":"9"},
    {"id":"202","landmark_az":"Siyaqut şadlıq sarayı","landmark_ru":"Дом торжеств Сиягут","landmark_en":"Siyagut restaurant","lattitude":"40.40219863","longitude":"49.95593034","district_id":"5"},
    {"id":"203","landmark_az":"Fəridə şadlıq sarayı","landmark_ru":"Дом торжеств Фериде","landmark_en":"Feride restaurant","lattitude":"40.37026413","longitude":"49.96011318","district_id":"9"},
    {"id":"204","landmark_az":"Mona Liza şadlıq sarayı","landmark_ru":"Дом торжеств Мона Лиза","landmark_en":"Mina Lisa restaurant","lattitude":"40.4091253","longitude":"49.86471407","district_id":"3"},
    {"id":"205","landmark_az":"Su səsi şadlıq sarayı","landmark_ru":"Дом торжеств Су Сеси","landmark_en":"Su Sesi restaurant","lattitude":"40.39817601","longitude":"49.93596393","district_id":"5"},
    {"id":"206","landmark_az":"Su sonası şadlıq sarayı","landmark_ru":"Дом торжеств Су Сонасы","landmark_en":"Su Sonasi restaurant","lattitude":"40.39254699","longitude":"49.85808011","district_id":"3"},
    {"id":"207","landmark_az":"Sapfir şadlıq sarayı","landmark_ru":"Дом торжеств Сапфир","landmark_en":"Sapphire restaurant","lattitude":"40.40360545","longitude":"49.93610414","district_id":"5"},
    {"id":"208","landmark_az":"Niel şadlıq sarayı","landmark_ru":"Дом торжеств Ниел","landmark_en":"Niel restaurant","lattitude":"40.40421764","longitude":"49.81712131","district_id":"4"},
    {"id":"209","landmark_az":"Kəmalə Nərmin şadlıq sarayı","landmark_ru":"Дом торжеств Кемале-Нермин","landmark_en":"Kamala-Nermin restaurant","lattitude":"40.42568491","longitude":"49.84684425","district_id":"2"},
    {"id":"210","landmark_az":"Hollivud şadlıq sarayı","landmark_ru":"Дом торжеств Голливуд","landmark_en":"Hollywood restaurant","lattitude":"40.42724564","longitude":"49.83944834","district_id":"2"},
    {"id":"211","landmark_az":"Badisəba şadlıq sarayı","landmark_ru":"Дом торжеств Бади Себа","landmark_en":"Badi Seba restaurant","lattitude":"40.40533546","longitude":"49.8063296","district_id":"4"},
    {"id":"212","landmark_az":"Aqat şadlıq sarayı","landmark_ru":"Дом торжеств Агат","landmark_en":"Agat restaurant","lattitude":"40.43020878","longitude":"49.95371951","district_id":"7"},
    {"id":"213","landmark_az":"Fidan şadlıq sarayı","landmark_ru":"Дом торжеств Фидан","landmark_en":"Fidan restaurant","lattitude":"40.43331464","longitude":"49.9533266","district_id":"7"},
    {"id":"214","landmark_az":"Arzu şadlıq sarayı","landmark_ru":"Дом торжеств Арзу","landmark_en":"Arzu restaurant","lattitude":"40.41520757","longitude":"49.96842604","district_id":"12"},
    {"id":"215","landmark_az":"Nargilə Zığ","landmark_ru":"Наргиле Зиг","landmark_en":"Nargile Zig","lattitude":"40.36874455","longitude":"49.93851543","district_id":"9"},
    {"id":"216","landmark_az":"Çinar restoranı","landmark_ru":"Ресторан Чинар","landmark_en":"Chinar restaurant","lattitude":"40.40797907","longitude":"49.9492111","district_id":"8"},
    {"id":"217","landmark_az":"Şam bağı","landmark_ru":"Шам баги","landmark_en":"Sham Bagi restaurant","lattitude":"40.35638583","longitude":"49.81160606","district_id":"8"},
    {"id":"218","landmark_az":"Şəki restoranı Əhməd Rəcəbli","landmark_ru":"Ресторан Шеки (Ахмад Ряджабли)","landmark_en":"Sheki restaurant (Ahmad Rajabli)","lattitude":"40.40869665","longitude":"49.8649005","district_id":"3"},
    {"id":"219","landmark_az":"Şəki restoranı Badamdar","landmark_ru":"Ресторан Шеки (Бадамдар)","landmark_en":"Sheki restaurant (Badamdar)","lattitude":"40.34953756","longitude":"49.81017207","district_id":"8"},
    {"id":"220","landmark_az":"Maşın Bazarı Xalqlar","landmark_ru":"Машын Базары Халглар","landmark_en":"Mashin Bazari Khalglar","lattitude":"40.39359627","longitude":"49.93488298","district_id":"9"},
    {"id":"221","landmark_az":"Mala Praqa Novxanı yolu","landmark_ru":"Малая Прага Новханы","landmark_en":"Malaya Praga Novkhana","lattitude":"40.39226152","longitude":"49.94164884","district_id":"1"},
    {"id":"222","landmark_az":"Cıdır düzü restoranı","landmark_ru":"Ресторан Джыдыр Дюзу","landmark_en":"Jidir Duzu restaurant","lattitude":"40.42614951","longitude":"49.84971823","district_id":"2"},
    {"id":"223","landmark_az":"Firuzə restoranı","landmark_ru":"Ресторан Фирузе","landmark_en":"Firuze restaurant","lattitude":"40.42911451","longitude":"49.95743078","district_id":"8"},
    {"id":"224","landmark_az":"Ağ saray Biləcəri","landmark_ru":"Аг Сарай Биладжари","landmark_en":"Ag Saray Biladjari","lattitude":"40.43700211","longitude":"49.80935581","district_id":"2"},
    {"id":"225","landmark_az":"Pluton hall","landmark_ru":"Дом торжеств Плутон Холл","landmark_en":"Pluton Hall restaurant","lattitude":"40.37954536","longitude":"49.94772338","district_id":"5"},
    {"id":"226","landmark_az":"Gilavar şadlıq sarayı","landmark_ru":"Дом торжеств Гилавар","landmark_en":"Gilavar restaurant","lattitude":"40.39577817","longitude":"49.8569062","district_id":"5"},
    {"id":"227","landmark_az":"Neptun saray","landmark_ru":"Дом торжеств Нептун","landmark_en":"Neptun restaurant","lattitude":"40.40701168","longitude":"49.92779822","district_id":"5"},
    {"id":"228","landmark_az":"Rich plaza","landmark_ru":"Рич плаза","landmark_en":"Rich Plaza","lattitude":"40.39055091","longitude":"49.89134574","district_id":"4"},
    {"id":"229","landmark_az":"Asiman Şadlıq Sarayı","landmark_ru":"Дом торжеств Асиман","landmark_en":"Asiman restaurant","lattitude":"40.37312652","longitude":"49.92829228","district_id":"9"},
    {"id":"230","landmark_az":"Kosmos restoranı","landmark_ru":"Ресторан Космос","landmark_en":"Cosmos restaurant","lattitude":"40.40043871","longitude":"49.88076241","district_id":"3"},
    {"id":"231","landmark_az":"Gəlin Qaya restoranı","landmark_ru":"Ресторан Гелин Гайа","landmark_en":"Gelin Gaya restaurant","lattitude":"40.34032111","longitude":"49.81017168","district_id":"8"},
    {"id":"232","landmark_az":"Çanaqqala restoranı","landmark_ru":"Ресторан Чанаггала","landmark_en":"Chanaggala restaurant","lattitude":"40.39324907","longitude":"49.85607367","district_id":"3"},
    {"id":"233","landmark_az":"Avrasiya klinikası","landmark_ru":"Аврасия клиника","landmark_en":"Avrasia Clinic","lattitude":"40.42426727","longitude":"49.83743977","district_id":"2"},
    {"id":"234","landmark_az":"Respublika Müalicəvi Diaqnostika Mərkəzi","landmark_ru":"Республиканский медицинский диагностический центр","landmark_en":"Republican Medical Diagnostic Center","lattitude":"40.3997602","longitude":"49.81040773","district_id":"4"},
    {"id":"235","landmark_az":"Onkoloji klinika","landmark_ru":"Клиника Онкологии","landmark_en":"Oncology clinic","lattitude":"40.39790237","longitude":"49.83318615","district_id":"4"},
    {"id":"236","landmark_az":"Baku Medical Babək","landmark_ru":"Баку Медикал Бабек","landmark_en":"Baku Medical Babek","lattitude":"40.39426561","longitude":"49.96476851","district_id":"9"},
    {"id":"237","landmark_az":"Baku Medical 28 may","landmark_ru":"Баку Медикал 28 мая","landmark_en":"Baku Medical May 28","lattitude":"40.38227313","longitude":"49.84384091","district_id":"4"},
    {"id":"238","landmark_az":"Baku Medical Medilux","landmark_ru":"Баку Медикал Medilux","landmark_en":"Baku Medical Medilux","lattitude":"40.40875792","longitude":"49.84645128","district_id":"3"},
    {"id":"239","landmark_az":"Vitamed Bakıxanov","landmark_ru":"Витамед Бакиханов","landmark_en":"Vitamed Bakihanov","lattitude":"40.41679474","longitude":"49.9671342","district_id":"12"},
    {"id":"240","landmark_az":"Semaşko xəstəxanası","landmark_ru":"Больница Семашко","landmark_en":"Semashko Hospital","lattitude":"40.39399436","longitude":"49.83152751","district_id":"3"},
    {"id":"241","landmark_az":"Medera Klinikası","landmark_ru":"Клиника Медера","landmark_en":"Medera clinic","lattitude":"40.41641007","longitude":"49.84150511","district_id":"2"},
    {"id":"242","landmark_az":"Oksigen klinikası","landmark_ru":"Клиника Оксиген","landmark_en":"Oksigen clinic","lattitude":"40.4036111","longitude":"49.87002253","district_id":"3"},
    {"id":"243","landmark_az":"Dunyagöz klinikası","landmark_ru":"Клиника Dunyagöz","landmark_en":"Dunyagoz clinic","lattitude":"40.34936363","longitude":"49.83583344","district_id":"8"},
    {"id":"244","landmark_az":"Sağlam Ailə Nərimanov","landmark_ru":"Sağlam Ailə Нариманов","landmark_en":"Saglam Aile Narimanov","lattitude":"40.40943222","longitude":"49.86203931","district_id":"3"},
    {"id":"245","landmark_az":"Mərkəzi Klinika","landmark_ru":"Центральная клиника","landmark_en":"Central clinic","lattitude":"40.36143188","longitude":"49.82488327","district_id":"8"},
    {"id":"246","landmark_az":"Mərkəzi Gömrük Hospitalı","landmark_ru":"Центральная таможенная больница","landmark_en":"Central Customs Hospital","lattitude":"40.37814531","longitude":"49.8188062","district_id":"11"},
    {"id":"247","landmark_az":"LOR hospital klinikası","landmark_ru":"Клиника LOR","landmark_en":"LOR clinic","lattitude":"40.40959882","longitude":"49.8632659","district_id":"3"},
    {"id":"248","landmark_az":"Bravo Koroğlu","landmark_ru":"Bravo Короглу","landmark_en":"Bravo Koroglu","lattitude":"40.41812037","longitude":"49.9135607","district_id":"5"},
    {"id":"249","landmark_az":"Bravo Əhmədli","landmark_ru":"Bravo Ахмедли","landmark_en":"Bravo Ahmedli","lattitude":"40.38811515","longitude":"49.95571661","district_id":"9"},
    {"id":"250","landmark_az":"Bravo 20 yanvar","landmark_ru":"Bravo 20 января","landmark_en":"Bravo 20 January","lattitude":"40.40161305","longitude":"49.81108969","district_id":"4"},
    {"id":"251","landmark_az":"Bravo Nərimanov","landmark_ru":"Браво Нариманов","landmark_en":"Bravo Narimanov","lattitude":"40.40010479","longitude":"49.85283564","district_id":"3"},
    {"id":"252","landmark_az":"Bravo Qlobus Centre","landmark_ru":"Bravo Qlobus Centre","landmark_en":"Bravo Qlobus Center","lattitude":"40.38518523","longitude":"49.8281697","district_id":"4"},
    {"id":"253","landmark_az":"Neptun supermarket 28 may","landmark_ru":"Супермаркет Neptun 28 мая","landmark_en":"Neptun supermarket 28 May","lattitude":"40.37779279","longitude":"49.84526977","district_id":"4"},
    {"id":"254","landmark_az":"Neptun supermarket Tbilisi","landmark_ru":"Супермаркет Neptun пр. Тбилиси","landmark_en":"Neptun supermarket Tbilisi","lattitude":"40.39709215","longitude":"49.8149284","district_id":"4"},
    {"id":"255","landmark_az":"Araz supermarket Tbilisi","landmark_ru":"Супермаркет Araz пр. Тбилиси","landmark_en":"Araz supermarket Tbilisi","lattitude":"40.39794253","longitude":"49.81395538","district_id":"4"},
    {"id":"256","landmark_az":"Rahat supermarket Nərimanov","landmark_ru":"Супермаркет Rahat Нариманов","landmark_en":"Rahat supermarket Narimanov","lattitude":"40.39811699","longitude":"49.82901801","district_id":"3"},
    {"id":"257","landmark_az":"Bolmart 28 may","landmark_ru":"Bolmart 28 мая","landmark_en":"Bolmart 28 May","lattitude":"40.38320423","longitude":"49.83807785","district_id":"4"},
    {"id":"258","landmark_az":"Murad market Novxanı","landmark_ru":"Murad маркет Новхани","landmark_en":"Murad Market Novkhani","lattitude":"40.5612563","longitude":"49.77833956","district_id":"1"},
    {"id":"259","landmark_az":"Fresco market Novxanı","landmark_ru":"Fresco маркет Новхани","landmark_en":"Fresco Market Novkhani","lattitude":"40.54086228","longitude":"49.78335233","district_id":"1"},
    {"id":"260","landmark_az":"Bravo Xətai","landmark_ru":"Bravo Хатаи","landmark_en":"Bravo Khatai","lattitude":"40.38759696","longitude":"49.87405125","district_id":"9"},
    {"id":"261","landmark_az":"İntourist hotel","landmark_ru":"Intourist Hotel","landmark_en":"Intourist Hotel","lattitude":"40.35392705","longitude":"49.83346609","district_id":"8"},
    {"id":"262","landmark_az":"Riviera hotel","landmark_ru":"Riviera Hotel","landmark_en":"Riviera Hotel","lattitude":"40.34973609","longitude":"49.83514847","district_id":"8"},
    {"id":"263","landmark_az":"Aynalı plaza","landmark_ru":"Айналы Плаза","landmark_en":"Aynali Plaza","lattitude":"40.4099933","longitude":"49.86359662","district_id":"3"},
    {"id":"264","landmark_az":"Babək plaza","landmark_ru":"Бабек Плаза","landmark_en":"Babek Plaza","lattitude":"40.38854669","longitude":"49.87229101","district_id":"9"},
    {"id":"265","landmark_az":"Caspian plaza","landmark_ru":"Caspian Плаза","landmark_en":"Caspian Plaza","lattitude":"40.38535662","longitude":"49.82874657","district_id":"4"},
    {"id":"266","landmark_az":"Gümüş plaza","landmark_ru":"Gümüş Плаза","landmark_en":"Gumush Plaza","lattitude":"40.36683811","longitude":"49.82574094","district_id":"11"},
    {"id":"267","landmark_az":"Çinar plaza","landmark_ru":"Çinar Плаза","landmark_en":"Çinar Plaza","lattitude":"40.41413624","longitude":"49.90362765","district_id":"5"},
    {"id":"268","landmark_az":"Altes plaza","landmark_ru":"Altes Плаза","landmark_en":"Altes Plaza","lattitude":"40.36820278","longitude":"49.82098144","district_id":"8"},
    {"id":"269","landmark_az":"Qlobus plaza","landmark_ru":"Qlobus Плаза","landmark_en":"Qlobus Plaza","lattitude":"40.39108198","longitude":"49.82317205","district_id":"4"},
    {"id":"270","landmark_az":"Avesta biznes mərkəzi","landmark_ru":"Avesta Business Center","landmark_en":"Avesta Business Center","lattitude":"40.40764943","longitude":"49.84761785","district_id":"3"},
    {"id":"271","landmark_az":"Nərimanov heykəli","landmark_ru":"Памятник Нариманов","landmark_en":"Narimanov Monument","lattitude":"40.36843265","longitude":"49.82440148","district_id":"11"},
    {"id":"272","landmark_az":"Qurd Qapısı","landmark_ru":"Гурд Гапысы","landmark_en":"Gurd Gapisi","lattitude":"40.35649462","longitude":"49.81000544","district_id":"8"}
    ]
    export default landmarks;