const rentData=[
    {
        "id": 1,
        "rent_name_az": "Alış",
        "rent_name_en": "Buy",
        "rent_name_ru": "Купить",
    },
    {
        "id": 2,
        "rent_name_az": "Kirayə aylıq",
        "rent_name_en": "Monthly rent",
        "rent_name_ru": "Месячная аренда",
    },
    {
        "id": 3,
        "rent_name_az": "Kirayə günlük",
        "rent_name_en": "Daily rent",
        "rent_name_ru": "Посуточная аренда",
    }
]
export default rentData;