
import React, { useState, useRef, useEffect } from 'react'
import apartment from '../data/apartmenData';
import cityData from '../data/cityData';
import rentData from "../data/rentData";
import { useLocation } from 'react-router-dom';
import villages from "../data/villages";
import landmarks from "../data/landmarks";
import metros from "../data/metros";
import districtData from '../data/districtData';
import trans from "../lang/translations";

const Search = () => {
    const lang = document.getElementById("root").dataset.lang
    const [searchQuery, setSearchQuery] = useState("")
    const [showRent, setShowRent] = useState(false)
    const [showApart, setShowApart] = useState(false);
    const [roomCount, setRoomCount] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [priceInput, setPriceInput] = useState(false);
    const [fieldInput, setFieldInput] = useState(false);
    const [sotFieldInput, setSotFieldInput] = useState(false);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const selectedOption = rentData.find((data) => data.id == 1);
    const [selectRent, setSelectRent] = useState(selectedOption[`rent_name_${lang}`]);
    const [chooseRent, setChooseRent] = useState("1")
    const selectValue = apartment.find((data) => data.id == 0);
    const [selectedApartment, setSelectedApartment] = useState(selectValue[`apartmentName_${lang}`]);
    const [selec, setSelec] = useState(0)
    const [selectedCity, setSelectedCity] = useState([]);
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [minkvmField, setMinkvmField] = useState("");
    const [maxkvmField, setMaxkvmField] = useState("");
    const [minsotField, setMinsotField] = useState("");
    const [maxsotField, setMaxsotField] = useState("");
    const [dminsot, setDminsot] = useState("")
    const [dmaxsot, setDmaxsot] = useState("")
    const [floorMin, setFloorMin] = useState("")
    const [floorMax, setFloorMax] = useState("")
    const [elan, setElan] = useState("")
    const roomCountRef = useRef("");
    const searchInputRef = useRef("");
    const [repairValue, setRepairValue] = useState([]);
    const [showplaceholder, setShowplaceholder] = useState(false)
    const [showplaceholderM, setShowplaceholderM] = useState(false)
    const [showplaceholderP, setShowplaceholderP] = useState(false)
    const [pInput, setPInput] = useState(minPrice && maxPrice ? `${minPrice} - ${maxPrice}` : `${trans[lang].price}, ${trans[lang].azn}`);
    const [sotInput, setSotInput] = useState(minsotField && maxsotField ? `${minsotField}-${maxsotField} ` : trans[`${lang}`].area)
    const [fInput, setFInput] = useState(minkvmField && maxkvmField ? `${minkvmField}-${maxkvmField}` : trans[`${lang}`].field)
    const roomPlaceholder = selectedRooms.length == 0 || roomCountRef.checked ? trans[`${lang}`].numberofrooms :  selectedRooms.length >=1 ? selectedRooms.join(', ') + trans[`${lang}`].rooms :  trans[`${lang}`].room;
    const [selectedLandmarks, setSelectedLandmarks] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectDistric, setSelectDistric] = useState([]);
    const [selectedMetro, setSelectedMetro] = useState([]);
    const detailWrapperRef = useRef("");
    const rentWrapperRef = useRef("");
    const roomWrapperRef = useRef("");
    const apartWrapperRef = useRef("");
    const priceWrapperRef = useRef("");
    const fieldWrapperRef = useRef("");
    const sotWrapperRef = useRef("");
    const searchWrapperRef = useRef("");
    const searchgroupRef = useRef("");
    const [officetype, setOfficetype] = useState([]);
    const [selectbox, setSelectbox] = useState({
        barter: false,
        domestic_credit: false,
        credit: false,
        useful_mortgage: false,
        ready_mortgage: false,
        electric_line: false,
        water_barrel: false,
        water_line: false,
        items: false,
        combi: false,
        view_sea: false,
        near_sea: false,
        pool: false,
        gas: false,
        gas_line: false,
        kupcha: false,
        nofirstfloor: false,
        nolastfloor: false
    });
    const refStatePairs = [
        { ref: detailWrapperRef, stateSetter: setShowDetail },
        { ref: rentWrapperRef, stateSetter: setShowRent },
        { ref: roomWrapperRef, stateSetter: setRoomCount },
        { ref: apartWrapperRef, stateSetter: setShowApart },
        { ref: priceWrapperRef, stateSetter: setPriceInput },
        { ref: fieldWrapperRef, stateSetter: setFieldInput },
        { ref: sotWrapperRef, stateSetter: setSotFieldInput },
    ];
    const handleRentSelect = (id) => {
        const selectedOption = rentData.find((data) => data.id == id);
        setSelectRent(selectedOption[`rent_name_${lang}`]);
        setChooseRent(selectedOption.id);
        setShowRent(false);
    };
    const handleDocumentClick = (e) => {
        for (const { ref, stateSetter } of refStatePairs) {
            if (ref.current && !ref.current.contains(e.target)) {
                stateSetter(false);
            }
        }
    };
    document.addEventListener('click', handleDocumentClick);
    const handleResetStates = (stateToToggle) => {
        setShowRent(stateToToggle == 'showRent' ? !showRent : false);
        setShowApart(stateToToggle == 'showApart' ? !showApart : false);
        setRoomCount(stateToToggle == 'roomCount' ? !roomCount : false);
        setPriceInput(stateToToggle == 'priceInput' ? !priceInput : false);
        setFieldInput(stateToToggle == 'fieldInput' ? !fieldInput : false);
        setSotFieldInput(stateToToggle == 'sotFieldInput' ? !sotFieldInput : false);
        setShowDetail(stateToToggle == 'showDetail' ? !showDetail : false);
    };
    const handleCheckApartment = (id) => {
        const selectedValue = apartment.find((data) => data.id == id);
        setSelectedApartment(selectedValue[`apartmentName_${lang}`]);
        setSelec(selectedValue.id);
        setShowApart(false);
    };
    const formatWithSpaces = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };
    const handleInputChange = (e, stateSetter) => {
        const formattedValue = e.target.value.replace(/\D/g, '');
        stateSetter(formatWithSpaces(formattedValue));
    };
    const handleEnterKeyPress = (e) => {
        if (minPrice && maxPrice) {
            setPInput(`${minPrice} - ${maxPrice}`);
            setShowplaceholderP(true)
        } else if (minPrice) {
            setPInput(`${trans[lang].min} ${minPrice}`);
            setShowplaceholderP(true)
        } else if (maxPrice) {
            setPInput(`${trans[lang].max} ${maxPrice}`);
            setShowplaceholderP(true)
        } else {
            setPInput(`${trans[lang].price}, ${trans[lang].azn}`);
            setShowplaceholderP(false)
        }
        e.preventDefault()
    };
    const EnterKeyPressSotField = (e) => {
        if (minsotField && maxsotField) {
            setSotInput(`${minsotField} - ${maxsotField}`);
            setShowplaceholder(true)
        } else if (minsotField) {
            setSotInput(`${trans[lang].min} ${minsotField}`);
            setShowplaceholder(true)
        } else if (maxsotField) {
            setSotInput(`${trans[lang].max} ${maxsotField}`);
            setShowplaceholder(true)
        } else {
            setSotInput(trans[`${lang}`].area);
            setShowplaceholder(false)
        }
        e.preventDefault()
    };
    const EnterKeyPressField = (e) => {
        if (minkvmField && maxkvmField) {
            setFInput(`${minkvmField} - ${maxkvmField}`);
            setShowplaceholderM(true)
        } else if (minkvmField) {
            setFInput(`${trans[lang].min} ${minkvmField}`);
            setShowplaceholderM(true)
        } else if (maxkvmField) {
            setFInput(`${trans[lang].max} ${minkvmField}`);
            setShowplaceholderM(true)
        } else {
            setFInput(trans[`${lang}`].field);
            setShowplaceholderM(false)
        }
        e.preventDefault()
    };
    const handleRoomSelection = (e) => {
        const checkboxValue = e.target.value
        const isChecked = e.target.checked
        if (isChecked) {
            setSelectedRooms(prevSelectedRooms => [...prevSelectedRooms, checkboxValue].sort((a, b) => a - b));
        } else {
            setSelectedRooms(prevSelectedRooms =>
                prevSelectedRooms.filter(room => room != checkboxValue)
            );
        }
    };
    const handleAllCheckRoom = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedRooms([]);
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="rooms[]"]');
            checkboxes.forEach((checkbox) => {
                if (checkbox != e.target) {
                    checkbox.checked = false;
                }
            });
            setRoomCount(false);
        }
    };
    const clearAllChecked = () => {
        setDminsot("")
        setDmaxsot("")
        setSelectedCity("")
        setSelectDistric("")
        setSelectedRegion("")
        setSelectedMetro("")
        setSelectedLandmarks("")
        setSelectRent(selectedOption[`rent_name_${lang}`])
        setChooseRent("1")
        setSelectedApartment(selectValue[`apartmentName_${lang}`])
        setSelec("0");
        setElan("")
        setMinPrice("");
        setMaxPrice("");
        setMinkvmField("");
        setMaxkvmField("");
        setMinsotField("");
        setMaxsotField("");
        setSelectedRooms("");
        setSotInput(trans[`${lang}`].area);
        setFInput(trans[`${lang}`].field);
        setPInput(`${trans[lang].price}, ${trans[lang].azn}`)
        setShowplaceholder(false)
        setShowplaceholderP(false)
        setShowplaceholderM(false)
        setFloorMax("")
        setFloorMin("")
        setRepairValue([])
        setShowDetail(false)
        setSelectbox({
            barter: false,
            domestic_credit: false,
            credit: false,
            useful_mortgage: false,
            ready_mortgage: false,
            electric_line: false,
            water_barrel: false,
            water_line: false,
            items: false,
            combi: false,
            view_sea: false,
            near_sea: false,
            pool: false,
            gas: false,
            gas_line: false,
            kupcha: false,
            nofirstfloor: false,
            nolastfloor: false

        })
    }
    const handleCityClick = (id) => {
        const isSelectedCity = selectedCity.includes(id);
        
        if (isSelectedCity) {
            const updatedCity = selectedCity.filter((selectCity) => selectCity != id);
            setSelectedCity(updatedCity);
        } else {
            setSelectedCity([...selectedCity, id]);
        }
        searchInputRef.current.focus()
    }
    const handleRegionClick = (id) => {
        const isSelectedRegion = selectedRegion.includes(Number(id));
        if (isSelectedRegion) {
            const updatedRegion = selectedRegion.filter((selectRegion) => selectRegion != id);
            setSelectedRegion(updatedRegion);
        } else {
            setSelectedRegion([...selectedRegion, id]);
        }
        searchInputRef.current.focus()
    };
    const handleDistrictClick = (id) => {
        const isSelectedDistrict = selectDistric.includes(Number(id));
        if (isSelectedDistrict) {
            const updatedDistricts = selectDistric.filter((districtId) => districtId != id);
            setSelectDistric(updatedDistricts);
        } else {
            setSelectDistric([...selectDistric, id]);
        }
        searchInputRef.current.focus()
    };
    const handleMetroClick = (id) => {
        const isSelected = selectedMetro.includes(Number(id));
        if (isSelected) {
            const updatedMetro = selectedMetro.filter((selectedMetro) => selectedMetro != id);
            setSelectedMetro(updatedMetro);
        } else {
            setSelectedMetro([...selectedMetro, id]);
        }
        searchInputRef.current.focus()
    };
    const handleLandmarkClick = (id) => {
        const isSelected = selectedLandmarks.includes(Number(id));
        if (isSelected) {
            const updatedLandmarks = selectedLandmarks.filter((selectedLandmark) => selectedLandmark != id);
            setSelectedLandmarks(updatedLandmarks);
        } else {
            setSelectedLandmarks([...selectedLandmarks, id]);
        }
        searchInputRef.current.focus()
    }
    const clearSearch = () => {
        setSearchQuery("")
    }
    const handleCheckboxChange = (name) => {
        setSelectbox((prevSelectbox) => ({
            ...prevSelectbox,
            [name]: !prevSelectbox[name],
        }));
    };
    const handleBuldingCheck = (value) => {
        if (officetype.includes(value)) {
            setOfficetype(prevTypes => prevTypes.filter(type => type != value));
        } else {
            setOfficetype(prevTypes => [...prevTypes, value]);
        }
    };
    const handleRadioChange = (value) => {
        if (repairValue.includes(value)) {
            setRepairValue(prevTypes => prevTypes.filter(type => type != value));
        } else {
            setRepairValue(prevTypes => [...prevTypes, value]);
        }
    }
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('type') ) {
            const type = queryParams.get('type');
            
            const prefixRooms = 'rooms';
            const RoomIdParams = [...queryParams.entries()]
                .filter(([key]) => key.startsWith(`${prefixRooms}[`));
            const rooms = [];
            RoomIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : rooms.length;
                rooms[index] = value;
            });

            const prefixCity = 'city_id';
            const CityIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixCity}[`));
            const cityid = [];
            CityIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : cityid.length;
                cityid[index] = parseInt(value);
            });

            const prefixDist = 'district_id';
            const DistIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixDist}[`));
            const districtid = [];
            DistIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : districtid.length;
                districtid[index] = parseInt(value);
            });

            const prefixVillage = 'villageid';
            const VillageIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixVillage}[`));
            const villageid = [];
            VillageIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : villageid.length;
                villageid[index] = parseInt(value);
            });

            const prefixMetro = 'metroid';
            const MetroIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixMetro}[`));
            const metroid = [];
            MetroIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : metroid.length;
                metroid[index] = parseInt(value);
            });

            const prefixLandmark = 'landmarkid';
            const LandmarkIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixLandmark}[`));
            const landmarkid = [];
            LandmarkIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : landmarkid.length;
                landmarkid[index] = parseInt(value);
            });

            const prefixRepair = 'repair';
            const RepairIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixRepair}[`));
            const repair = [];
            RepairIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : repair.length;
                repair[index] = parseInt(value);
            });

            const prefixofficetype = 'officetype';
            const OfficetypeIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixofficetype}[`));
            const officetype = [];
            OfficetypeIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : officetype.length;
                officetype[index] = parseInt(value);
            });
 
            const adsnumber = queryParams.get('adsnumber');
            const category = queryParams.get('category');
            const pmin = queryParams.get('pmin');
            const pmax = queryParams.get('pmax');
            const minsot = queryParams.get('minsot');
            const maxsot = queryParams.get('maxsot');
            const minkvm = queryParams.get('minkvm');
            const maxkvm = queryParams.get('maxkvm');
            const floor_min = queryParams.get('floor_min');
            const floor_max = queryParams.get('floor_max');
            const nofirstfloor = queryParams.get('nofirstfloor');
            const nolastfloor = queryParams.get('nolastfloor');
            const kupcha = queryParams.get('kupcha');
            const credit = queryParams.get('credit');
            const useful_mortgage = queryParams.get('useful_mortgage');
            const ready_mortgage = queryParams.get('ready_mortgage');
            const electric_line = queryParams.get('electric_line');
            const water_line = queryParams.get('water_line');
            const gas_line = queryParams.get('gas_line');
            const items = queryParams.get('items');            
            const gas = queryParams.get('gas');
            const water_barrel = queryParams.get('water_barrel');
            const combi = queryParams.get('combi');
            const view_sea = queryParams.get('view_sea');
            const near_sea = queryParams.get('near_sea');
            const pool = queryParams.get('pool');
            const barter = queryParams.get('barter');
            const domestic_credit = queryParams.get('domestic_credit');
            setSelectbox((prevSelectbox) => ({
                ...prevSelectbox,
                barter,
                domestic_credit,
                credit,
                useful_mortgage,
                ready_mortgage,
                electric_line,
                water_barrel,
                water_line,
                items,
                combi,
                view_sea,
                near_sea,
                pool,
                gas,
                gas_line,
                kupcha,
                nofirstfloor,
                nolastfloor
            }));
            setSelectedRooms(rooms)
            setRepairValue(repair)
            setOfficetype(officetype)
            setSelectedCity(cityid)
            setSelectDistric(districtid)
            setSelectedRegion(villageid)
            setSelectedMetro(metroid)
            setSelectedLandmarks(landmarkid)
            setElan(adsnumber)
            setSelectRent(rentData[type - 1][`rent_name_${lang}`])
            setChooseRent(type)
            setDminsot(minsot)
            setDmaxsot(maxsot)
            setMinPrice(pmin)
            setMaxPrice(pmax)
            setMinkvmField(minkvm)
            setMaxkvmField(maxkvm)
            setFInput(minkvm && maxkvm ? `${minkvm}-${maxkvm}` : trans[`${lang}`].field)
            setShowplaceholderM(minkvm && maxkvm ? true : false)
            setMinsotField(minsot)
            setMaxsotField(maxsot)
            setSotInput(minsot && maxsot ? `${minsot}-${maxsot} ` : trans[`${lang}`].area)
            setShowplaceholder(minsot && maxsot ? true : false)
            setFloorMin(floor_min)
            setFloorMax(floor_max)
            setPInput(pmin && pmax ? `${pmin} - ${pmax}` : `${trans[lang].price}, ${trans[lang].azn}`)
            setShowplaceholderP(pmin && pmax ? true : false)
            if (category == 9) {
                setSelectedApartment(apartment[4][`apartmentName_${lang}`]);
                setSelec(9)
            } else if (category >= 4) {
                setSelectedApartment(apartment[Number(category) + 1][`apartmentName_${lang}`]);
                setSelec(Number(category));
            } else {
                setSelectedApartment(apartment[category][`apartmentName_${lang}`]);
                setSelec(category)
            }

        } else {

        }
    }, []);
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
                setSearchQuery('');
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchWrapperRef]);

    return (
        <form action={lang==="az" ? "/elanlar" : `/${lang}/elanlar`} method="GET" autoComplete='off' encType="multipart/form-data">
            <div className="wx-search-container-section">
                <div className='wx-search-container'>
                    <div className='row wx-search-row'>
                        <div className='col-md-1 p-1'>
                            <div className='wx-search d-flex' id='menu' ref={rentWrapperRef} onClick={() => handleResetStates('showRent')}>
                                <div className='wx-rent-wrapper' >
                                    <div className='wx-rent-input d-flex align-items-center justify-content-center'>
                                        <input type="text" className='form-control' placeholder={selectRent} readOnly />
                                        <input name="type" type="hidden" value={chooseRent} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showRent ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-rent-list wx-list wx-list-style ${showRent ? 'active' : 'deactive'}`}>
                                        {rentData.map((data, id) => {
                                            return <ul key={id}>
                                                <li onClick={() => handleRentSelect(Number(data.id))}>
                                                    {data[`rent_name_${lang}`]}
                                                    <svg style={{ display: chooseRent == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            </ul>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 p-1'>
                            <div className='wx-search p-0' id='wx-location-search' ref={searchWrapperRef} >
                                <div className="wx-search-section">
                                    <div className="wx-search-input p-2">
                                        <input className='wx-modal-input form-control' type="text" placeholder={trans[`${lang}`].locationtitle}
                                            value={searchQuery} ref={searchInputRef}
                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                        {searchQuery ? (
                                        <div onClick={clearSearch}>
                                            <img src="/icons/close.png" alt="map pin" width={30}/>
                                        </div>) : (
                                        <div>
                                            <img src="/icons/search-location.png" alt="map pin" width={30}/>
                                        </div>)}
                                    </div>

                                    <div className="wx-search-result-section">
                                        <ul>
                                            {searchQuery && (
                                                districtData
                                                    .filter((district) =>
                                                        district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((district, index) => (
                                                        <li key={index} onClick={() => handleDistrictClick(Number(district.district_id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{district[`districtname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].district}</p>
                                                            </div>
                                                            <svg style={{ display: selectDistric.includes(Number(district.district_id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                villages
                                                    .filter((village) =>
                                                        village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                    )
                                                    .map((village, id) => (
                                                        <li key={id} onClick={() => handleRegionClick(Number(village.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{village[`village_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].villiage}</p>
                                                            </div>
                                                            <svg style={{
                                                                display: selectedRegion.includes(Number(village.id)) ? "inline-block" : "none",
                                                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                metros
                                                    .filter((metro) =>
                                                        metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((metro, id) => (
                                                        <li key={id} onClick={() => handleMetroClick(Number(metro.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{metro[`metro_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].metro}</p>
                                                            </div>
                                                            <svg style={{ display: selectedMetro.includes(Number(metro.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    )))}
                                            {searchQuery && (
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).map((landmark, id) => (
                                                    <li key={id} onClick={() => handleLandmarkClick(Number(landmark.id))}>
                                                        <div className='d-flex flex-column wx-location-name-box '>
                                                            <p className='wx-location-name'>{landmark[`landmark_${lang}`]}</p>
                                                            <p className='wx-location-title'>{trans[`${lang}`].landmark}</p>
                                                        </div>
                                                        <svg style={{ display: selectedLandmarks.includes(Number(landmark.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            className='bi bi-check'
                                                            fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                        </svg>
                                                    </li>
                                                ))
                                            )}
                                             {searchQuery && (
                                                cityData
                                                    .filter((city) =>
                                                        city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((city, index) => (
                                                        <li key={index} onClick={() => handleCityClick(city.city_id)}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{city[`cityname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].city}</p>
                                                            </div>
                                                            <svg style={{ display: selectedCity.includes(city.city_id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {(searchQuery &&
                                                cityData.filter((city) =>
                                                    city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                districtData.filter((district) =>
                                                    district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                villages.filter((village) =>
                                                    village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                metros.filter((metro) =>
                                                    metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0) && (
                                                    <li className="no-results"><p>{trans[`${lang}`].notfinedresult} <span className="fn-18"> &#9785;</span> </p></li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-1-5 p-1'>
                            <div className='wx-search d-flex' id='apartment' ref={apartWrapperRef} onClick={() => handleResetStates('showApart')}>
                                <div className='wx-apartment-wrapper'>
                                    <div className='wx-apartment-input d-flex align-items-center justify-content-center'>
                                        <input type="text" className='form-control' placeholder={selectedApartment} readOnly />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showApart ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-apartment-list wx-list wx-list-style ${showApart ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                        <input type="hidden" name="category" value={selec} />
                                        <ul>
                                            {apartment.map((data, index) => {
                                                return <li onClick={() => handleCheckApartment(data.id)} key={index}>{data[`apartmentName_${lang}`]}
                                                    <svg style={{ display: selec == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        {selec != 5 &&
                        <div className='col-md-1-5 p-1'>
                            <div className='wx-search d-flex' ref={fieldWrapperRef}
                                onClick={() => {
                                    handleResetStates("fieldInput")
                                }} >
                                <div>
                                    <div className="wx-showplace">
                                        <div className={`inputbox ${showplaceholderM ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].field}</span></div>
                                        <div className='field-wrapper  field-inp '>
                                            <input type="text" className='form-control' placeholder={fInput} readOnly />
                                        </div>
                                    </div>
                                    <div className={`wx-field wx-list ${fieldInput ? 'active' : 'deactive'}`} onBlur={EnterKeyPressField} >
                                        <div className="d-flex" >
                                            <div>
                                            <span>{trans[`${lang}`].minimum}</span>
                                            <div className='wx-field-input me-2' onClick={(e) => e.stopPropagation()}>
                                                <input name="minkvm" maxLength={8} className='wx-min-input form-control' type="text" placeholder={trans[`${lang}`].min} value={minkvmField} onChange={(e) => handleInputChange(e, setMinkvmField)} />
                                            </div>
                                            </div>
                                            <div>
                                            <span className={minkvmField && maxkvmField && typeof minkvmField === 'string' && typeof maxkvmField === 'string' && parseInt(minkvmField.replace(/\D/g, ''))>=parseInt(maxkvmField.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                            <div className='wx-field-input' onClick={(e) => e.stopPropagation()}>
                                                <input name="maxkvm" maxLength={8} 
                                                  className={`wx-max-input form-control ${minkvmField && maxkvmField && typeof minkvmField === 'string' && typeof maxkvmField === 'string' && parseInt(minkvmField.replace(/\D/g, ''))>=parseInt(maxkvmField.replace(/\D/g, '')) ? `text-danger` : ""}`}
                                                type="text" placeholder={trans[`${lang}`].max} value={maxkvmField} onChange={(e) => handleInputChange(e, setMaxkvmField)} />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {selec == 5 &&
                        <div className='col-md-1-5 p-1'>
                            <div className='wx-search d-flex' ref={sotWrapperRef} onClick={() => handleResetStates("sotFieldInput")} >
                                <div className="wx-showplace" >
                                    <div className={`inputbox ${showplaceholder ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].area}</span></div>
                                    <div className='wx-field-wrapper  field-inp '>
                                        <input type="text" className='form-control' placeholder={sotInput} readOnly />
                                    </div>
                                </div>
                                <div className={`wx-field wx-list ${sotFieldInput ? 'active' : 'deactive'}`} onBlur={EnterKeyPressSotField}>
                                    <div className="d-flex" >
                                        <div>
                                        <span>{trans[`${lang}`].minimum}</span>
                                        <div className='wx-field-input  me-2' onClick={(e) => e.stopPropagation()}>
                                            <input name="minsot" className='wx-range-input' type="text" placeholder={trans[`${lang}`].min} maxLength={8} value={minsotField} onChange={(e) => handleInputChange(e, setMinsotField)} />
                                        </div>
                                        </div>
                                        <div>
                                        <span className={minsotField && maxsotField && typeof minsotField === 'string' && typeof maxsotField === 'string' && parseInt(minsotField.replace(/\D/g, ''))>=parseInt(maxsotField.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                        <div className='wx-field-input' onClick={(e) => e.stopPropagation()}>
                                            <input name="maxsot" maxLength={8} className={`wx-range-input ${minsotField && maxsotField && typeof minsotField === 'string' && typeof maxsotField === 'string' && parseInt(minsotField.replace(/\D/g, ''))>=parseInt(maxsotField.replace(/\D/g, '')) ? `text-danger` : ""}`} type="text" placeholder={trans[`${lang}`].max} value={maxsotField} onChange={(e) => handleInputChange(e, setMaxsotField)} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className='col-md-1-5 p-1'>
                            <div className='wx-search d-flex' ref={priceWrapperRef} onClick={() => handleResetStates('priceInput')}>
                                <div className="wx-showplace" >
                                    <div className={`inputbox ${showplaceholderP ? 'active' : 'deactive'}`}> <span>{trans[`${lang}`].price}, {trans[`${lang}`].azn}</span></div>
                                    <div className=' price-wrapper d-flex align-center  wx-price-inp'>
                                        <input type="text" className='form-control' placeholder={pInput} readOnly />
                                    </div>
                                </div>
                                <div className={`wx-price wx-list ${priceInput ? 'active' : 'deactive'}`} onBlur={handleEnterKeyPress}>
                                    <div className="d-flex">
                                        <div>
                                            <span>{trans[`${lang}`].minimum}</span>
                                            <div className='wx-price-input min-inpt me-2' onClick={(e) => e.stopPropagation()}>
                                                <input name="pmin" className='wx-min-input form-control' maxLength={12} type="text" placeholder={trans[`${lang}`].min} value={minPrice} onChange={(e) => handleInputChange(e, setMinPrice)} />
                                            </div>
                                        </div>
                                        <div>
                                            <span className={minPrice && maxPrice && typeof minPrice === 'string' && typeof maxPrice === 'string' && parseInt(minPrice.replace(/\D/g, ''))>=parseInt(maxPrice.replace(/\D/g, '')) ? `text-danger` : ""}>{trans[`${lang}`].maximum}</span>
                                        <div className='wx-price-input' onClick={(e) => e.stopPropagation()}>
                                            <input name="pmax" className={`wx-max-input form-control ${minPrice && maxPrice && typeof minPrice === 'string' && typeof maxPrice === 'string' && parseInt(minPrice.replace(/\D/g, ''))>=parseInt(maxPrice.replace(/\D/g, '')) ? 'text-danger' : ''}`} maxLength={12} type="text" placeholder={trans[`${lang}`].max} value={maxPrice} onChange={(e) => handleInputChange(e, setMaxPrice)} />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className='col-md-1-5 p-1'>
                            <div className={`wx-search d-flex ${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'mydisabled' : ""}`} id='rooms' ref={roomWrapperRef} onClick={() => handleResetStates('roomCount')}>
                                    <>
                                    <div className='wx-rooms-inputs'>
                                            <input className={`form-control ${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'mydisabled' : ""}`} type="text"  ref={roomCountRef} placeholder={roomPlaceholder} readOnly />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${roomCount && (selec != 5 && selec != 8) ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={` ${(selec == 5 || selec == 6 || selec == 7 || selec == 8) ? 'disabled' : 'rooms-wrapper'}`}  >
                                        <div className={`wx-room-list wx-list wx-list-style ${roomCount ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                            <ul>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        {trans[`${lang}`].anynumberofrooms}
                                                        <input checked={selectedRooms.length == 0} type="checkbox" onChange={handleAllCheckRoom} className='form-control' />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        1 {trans[`${lang}`].room}
                                                        <input type="checkbox" value="1" name="rooms[]" checked={selectedRooms.includes("1")} className='form-control'  onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        2 {trans[`${lang}`].rooms}
                                                        <input type="checkbox" value="2" className='form-control'  name="rooms[]" checked={selectedRooms.includes("2")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        3 {trans[`${lang}`].rooms}
                                                        <input id='otaq3' value="3" className='form-control'  type="checkbox" name="rooms[]" checked={selectedRooms.includes("3")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        4 {trans[`${lang}`].rooms}
                                                        <input type="checkbox" className='form-control'  name="rooms[]" value="4" checked={selectedRooms.includes("4")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="wx-checkbox-label">
                                                        5 {trans[`${lang}`].roomsmore}
                                                        <input type="checkbox" className='form-control'  value="5" name="rooms[]" checked={selectedRooms.includes("5")} onChange={handleRoomSelection} />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>

                        <div className='col-md-2 p-1'>
                            <div className="wx-search p-0">
                                <div className="button-section">
                                    <div className='wx-detail-wrapper' >
                                        <div className='wx-search-more-icons'>
                                            <div ref={detailWrapperRef} onClick={() => handleResetStates('showDetail')} className='p-1 px-4'>
                                                <img src="/icons/filter.png" alt="search filter" width={25}/>
                                            </div>
                                            <div id="wx-buton" >
                                                <button type="submit" className='btn wx-btn'>{trans[`${lang}`].dosearch}</button>
                                            </div>
                                        </div>
                                        <div className={`wx-detailed-search ${showDetail ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                            <div className="wx-search-locations" >
                                                <div>
                                                    <div className="wx-search-locations-buttons d-flex" >
                                                        <div className='wx-search-elan'>
                                                            {selec != 5 && selec != 6 &&
                                                                <div className="wx-search-group" ref={searchgroupRef}>
                                                                    <div className="wx-more-detail_search wx-search-radio-group d-flex">
                                                                        <label className='new-checkbox_btn-label'>
                                                                            <input value={1} type="checkbox" name="repair[]" className='wx-checkbox_btn ' checked={ repairValue.includes(1)}   onChange={() => handleRadioChange(1)}
                                                                            />
                                                                            <span className='wx-radio-btn'>{trans[`${lang}`].normalrepaired}</span>
                                                                        </label>
                                                                        <label className='new-checkbox_btn-label'>
                                                                            <input value={2} type="checkbox" name="repair[]" checked={ repairValue.includes(2)} onChange={() => handleRadioChange(2)}
                                                                            />
                                                                            <span className='wx-radio-btn'>{trans[`${lang}`].goodrepaired}</span>
                                                                        </label>
                                                                        <label className='new-checkbox_btn-label noright'>
                                                                            <input value={0} type="checkbox" name="repair[]" checked={ repairValue.includes(0)} onChange={() => handleRadioChange(0)}
                                                                            />
                                                                            <span className='wx-radio-btn'>{trans[`${lang}`].norepaired}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="wx-search-regular wx-more-detail_search d-flex">
                                                            <label className="wx-checkbox_btn-label">
                                                                <input type="checkbox" name="kupcha" checked={selectbox.kupcha}
                                                                    onChange={() => handleCheckboxChange('kupcha')} className='wx-checkbox_btn' />
                                                                <span className='wx-filter-checkbox'>{trans[`${lang}`].kupcha}</span>
                                                            </label>

                                                            <div className='wx-elan-number' >
                                                                <label></label>
                                                                    <input name="adsnumber" maxLength={12} placeholder={trans[`${lang}`].adsnumber} type="text" className='adsnumber' value={elan} onChange={(e) => {if (/^[0-9]*$/.test(e.target.value)) {setElan(e.target.value);}}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div className='wx-area mt-1 mb-2'>
                                                        {(selec == 3 || selec == 9) && (
                                                            <div>
                                                                <div className='wx-search-label'>
                                                                    <span>{trans[`${lang}`].landarea}</span>
                                                                </div>
                                                                <div className='wx-range-inputs'>
                                                                    <input value={dminsot} maxLength={8} name="minsot" type="text" className='wx-range-input' placeholder={trans[`${lang}`].min}
                                                                        onChange={(e) => handleInputChange(e, setDminsot)} />
                                                                    <input value={dmaxsot} name="maxsot" type="text" maxLength={8} 
                                                                    className={`wx-range-input ${dminsot && dmaxsot && typeof dminsot === 'string' && typeof dmaxsot === 'string' && parseInt(dminsot.replace(/\D/g, '')) >= parseInt(dmaxsot.replace(/\D/g, '')) ? 'text-danger' : ''}`}
                                                                     placeholder={trans[`${lang}`].max} onChange={(e) => handleInputChange(e, setDmaxsot)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {(selec == 0 || selec == 1 || selec == 2) && (
                                                        <div className='wx-floor'>
                                                            <div>
                                                                <div className='wx-floor-label'>
                                                                    <span>{trans[`${lang}`].floor}</span>
                                                                </div>
                                                                <div className='wx-range-inputs'>
                                                                    <input name="floor_min" value={floorMin} type="text" maxLength={2} className='wx-range-input' placeholder={trans[`${lang}`].min} onChange={(e) => setFloorMin(e.target.value)} onKeyPress={(e) => {
                                                                    const isValidInput = /^-?[0-9,'.',' ']*$/.test(e.target.value + e.key);
                                                                    if (!isValidInput) {
                                                                    e.preventDefault();
                                                                    }
                                                                }}/>
                                                                    <input name="floor_max" value={floorMax} type="text" maxLength={2} className={`wx-range-input ${floorMin && floorMax && typeof floorMin === 'string' && typeof floorMax === 'string' && parseInt(floorMin.replace(/\D/g, '')) >= parseInt(floorMax.replace(/\D/g, '')) ? 'text-danger' : ''}`} placeholder={trans[`${lang}`].max} onChange={(e) => setFloorMax(e.target.value)} onKeyPress={(e) => {
                                                                    const isValidInput = /^-?[0-9,'.',' ']*$/.test(e.target.value + e.key);
                                                                    if (!isValidInput) {
                                                                    e.preventDefault();
                                                                    }
                                                                }}/>
                                                                </div>
                                                            </div>
                                                            <div className='wx-checkbox-floor wx-more-detail_search'>
                                                                <label className="wx-checkbox_btn-label h-38">
                                                                    <input type="checkbox" name="nofirstfloor" checked={selectbox.nofirstfloor}
                                                                        onChange={() => handleCheckboxChange('nofirstfloor')} />
                                                                    <span className='wx-filter-checkbox'>{trans[`${lang}`].nofirstfloor}</span>
                                                                </label>
                                                                <label className="wx-checkbox_btn-label h-38">
                                                                    <input type="checkbox" name="nolastfloor" checked={selectbox.nolastfloor} onChange={() => handleCheckboxChange('nolastfloor')} />
                                                                    <span className='wx-filter-checkbox'>{trans[`${lang}`].notopfloor}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {selec == 4 && (
                                                    <div className='wx-building-type mb-2'>
                                                        <div className='wx-building-label'>
                                                            <span>{trans[`${lang}`].typeofbuilding}</span>
                                                        </div>
                                                        <div className='wx-checkbox-building wx-more-detail_search'>
                                                            <label className="wx-checkbox_btn-label">
                                                                <input
                                                                    value="1"
                                                                    name="officetype[]"
                                                                    className='wx-checkbox_btn'
                                                                    type="checkbox"
                                                                    checked={officetype.includes(1)}
                                                                    onChange={() => handleBuldingCheck(1)}
                                                                />
                                                                <span className='wx-filter-checkbox'>{trans[`${lang}`].businesscenter}</span>
                                                            </label>
                                                            <label className="wx-checkbox_btn-label">
                                                                <input
                                                                    value="2"
                                                                    name="officetype[]"
                                                                    type="checkbox"
                                                                    className='wx-checkbox_btn'
                                                                    checked={officetype.includes(2)}
                                                                    onChange={() => handleBuldingCheck(2)}
                                                                />
                                                                <span className='wx-filter-checkbox'>{trans[`${lang}`].homebuilding}</span>
                                                            </label>
                                                            <label className="wx-checkbox_btn-label">
                                                                <input
                                                                    value="3"
                                                                    name="officetype[]"
                                                                    type="checkbox"
                                                                    className='wx-checkbox_btn'
                                                                    checked={officetype.includes(3)}
                                                                    onChange={() => handleBuldingCheck(3)}
                                                                />
                                                                <span className='wx-filter-checkbox'>{trans[`${lang}`].villa}</span>
                                                            </label>
                                                            <label className="wx-checkbox_btn-label">
                                                                <input
                                                                    value="4"
                                                                    name="officetype[]"
                                                                    type="checkbox"
                                                                    className='wx-checkbox_btn'
                                                                    checked={officetype.includes(4)}
                                                                    onChange={() => handleBuldingCheck(4)}
                                                                />
                                                                <span className='wx-filter-checkbox'>{trans[`${lang}`].coworking}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='wx-more-detail'>
                                                {chooseRent == 1 && (
                                                    <div className='wx-more-detail_search d-flex'>
                                                        <label className='wx-checkbox_btn-label'>
                                                            <input className='wx-checkbox_btn'
                                                                type="checkbox"
                                                                name="barter"
                                                                checked={selectbox.barter}
                                                                onChange={() => handleCheckboxChange('barter')}
                                                            />
                                                            <span>{trans[`${lang}`].barter}</span>
                                                        </label>
                                                        <label className='wx-checkbox_btn-label'>
                                                            <input
                                                                name="domestic_credit"
                                                                className='wx-checkbox_btn'
                                                                type="checkbox"
                                                                checked={selectbox.domestic_credit}
                                                                onChange={() => handleCheckboxChange('domestic_credit')}
                                                            />
                                                            <span>{trans[`${lang}`].internalcredit}</span>
                                                        </label>
                                                        <label className='wx-checkbox_btn-label'>
                                                            <input
                                                                name="credit"
                                                                className='wx-checkbox_btn'
                                                                type="checkbox"
                                                                checked={selectbox.credit}
                                                                onChange={() => handleCheckboxChange('credit')}
                                                            />
                                                            <span>{trans[`${lang}`].credit}</span>
                                                        </label>
                                                        <label className='wx-checkbox_btn-label'>
                                                            <input
                                                                name="useful_mortgage"
                                                                className='wx-checkbox_btn'
                                                                type="checkbox"
                                                                checked={selectbox.useful_mortgage}
                                                                onChange={() => handleCheckboxChange('useful_mortgage')}
                                                            />
                                                            <span>{trans[`${lang}`].usefullmortgage}</span>
                                                        </label>
                                                        <label className='wx-checkbox_btn-label'>
                                                            <input
                                                                name="ready_mortgage"
                                                                className='wx-checkbox_btn'
                                                                type="checkbox"
                                                                checked={selectbox.ready_mortgage}
                                                                onChange={() => handleCheckboxChange('ready_mortgage')}
                                                            />
                                                            <span>{trans[`${lang}`].readymortgage}</span>
                                                        </label>
                                                    </div>
                                                )}
                                                {selec != 8 &&
                                                    <>
                                                        {selec != 5 && selec != 6 && selec != 7 &&
                                                            <div className='wx-more-detail_search mt-3 d-flex'>
                                                                    <label className='wx-checkbox_btn-label'>
                                                                        <input name="items" className='wx-checkbox_btn' type="checkbox"
                                                                            checked={selectbox.items}
                                                                            onChange={() => handleCheckboxChange('items')} />
                                                                        <span>{trans[`${lang}`].items}</span>
                                                                    </label>
                                                                {selec != 4 &&
                                                                    <>
                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="gas" className='wx-checkbox_btn' type="checkbox" checked={selectbox.gas}
                                                                                onChange={() => handleCheckboxChange('gas')} />
                                                                            <span>{trans[`${lang}`].gas}</span>
                                                                        </label>
                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="water_barrel" className='wx-checkbox_btn' type="checkbox" checked={selectbox.water_barrel}
                                                                                onChange={() => handleCheckboxChange('water_barrel')} />
                                                                            <span>{trans[`${lang}`].waterbarrel}</span>
                                                                        </label>
                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="combi" className='wx-checkbox_btn' type="checkbox" checked={selectbox.combi}
                                                                                onChange={() => handleCheckboxChange('combi')} />
                                                                            <span>{trans[`${lang}`].combi}</span>
                                                                        </label>
                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="view_sea" className='wx-checkbox_btn' type="checkbox" checked={selectbox.view_sea}
                                                                                onChange={() => handleCheckboxChange('view_sea')} />
                                                                            <span>{trans[`${lang}`].viewsea}</span>
                                                                        </label>
                                                                    </>
                                                                    }
                                                            
                                                                {(selec == 3 || selec == 9) && (
                                                                    <>
                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="pool" className='wx-checkbox_btn' type="checkbox" checked={selectbox.pool}
                                                                                onChange={() => handleCheckboxChange('pool')} />
                                                                            <span>{trans[`${lang}`].pool}</span>
                                                                        </label>

                                                                        <label className='wx-checkbox_btn-label'>
                                                                            <input name="near_sea" className='wx-checkbox_btn' type="checkbox" checked={selectbox.near_sea}
                                                                                onChange={() => handleCheckboxChange('near_sea')} />
                                                                            <span>{trans[`${lang}`].nearsea}</span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </div>
                                                        }

                                                        <div className='wx-more-detail_search mt-3 d-flex'>
                                                            {selec == 5 &&
                                                                <label className='wx-checkbox_btn-label'>
                                                                    <input name="electric_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.electric_line}
                                                                        onChange={() => handleCheckboxChange('electric_line')} />
                                                                    <span>{trans[`${lang}`].electricline}</span>
                                                                </label>
                                                            }
                                                            {selec == 5 &&
                                                                <label className='wx-checkbox_btn-label'>
                                                                    <input name="water_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.water_line}
                                                                        onChange={() => handleCheckboxChange('water_line')} />
                                                                    <span>{trans[`${lang}`].waterline}</span>
                                                                </label>}
                                                            {selec == 5 &&
                                                                <label className='wx-checkbox_btn-label'>
                                                                    <input name="gas_line" className='wx-checkbox_btn' type="checkbox" checked={selectbox.gas_line}
                                                                        onChange={() => handleCheckboxChange('gas_line')} />
                                                                    <span>{trans[`${lang}`].gasline}</span>
                                                                </label>}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className='wx-search-foot'>
                                                <div className='hiddenn' onClick={() => setShowDetail(false)}>
                                                    <span>{trans[`${lang}`].hide}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-chevron-up ms-1" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                    </svg>
                                                </div>
                                                <div className='wx-buttons'>
                                                    <div className='wx-btnClearAll' onClick={clearAllChecked}>
                                                        <input type="reset" value={trans[`${lang}`].reset} />
                                                    </div>
                                                    <button className='btn wx-btn-show'><span>{trans[`${lang}`].showannouns}</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wx-addAllChecked'>
                    <ul className='row'>
                        {selectDistric &&
                            selectDistric.filter((data) => data.id == districtData.district_id).map((item, i) => (
                                <li key={i} onClick={() => handleDistrictClick(Number(item))}>
                                    <input name='district_id[]' value={selectDistric[i]} type="hidden" />
                                    <div className='wx-box'>
                                        <span>  {districtData[item - 1][`districtname_${lang}`]}</span>
                                        <span className='wx-title'>{trans[`${lang}`].district}</span>
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-x mb-1"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </li>
                            ))
                        }
                        {selectedRegion &&
                            selectedRegion.filter((data) => data.id == villages.id)
                                .map((item, i) => (
                                    <li key={i} onClick={() => handleRegionClick(Number(item))}>
                                        <input name='villageid[]' value={selectedRegion[i]} type="hidden" />
                                        <div className='wx-box'>
                                            <span>{villages[item - 1][`village_${lang}`]}</span>
                                            <span className='wx-title'>{trans[`${lang}`].villiage}</span>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </li>
                                ))
                        }
                        {selectedMetro &&
                            selectedMetro.filter((data) => data.id == metros.id).map((item, i) => (
                                <li key={i} onClick={() => handleMetroClick(Number(item))}>
                                    <input name='metroid[]' value={selectedMetro[i]} type="hidden" />
                                    <div className='wx-box'>
                                        <span> {metros[item - 1][`metro_${lang}`]}</span>
                                        <span className='wx-title'>{trans[`${lang}`].metro}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x " viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </li>
                            ))}
                        {selectedLandmarks && selectedLandmarks.filter((data) => data.id == landmarks.id).map((item, i) => (
                            <li key={i} onClick={() => handleLandmarkClick(Number(item))}>
                                <input name='landmarkid[]' value={selectedLandmarks[i]} type="hidden" />
                                <div className='wx-box'>
                                    <span>{landmarks[item - 1][`landmark_${lang}`]}</span>
                                    <span className='wx-title'>{trans[`${lang}`].landmark}</span>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x mb-1" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </li>
                        ))}
                         {selectedCity &&
                            selectedCity.filter((data) => data.id == cityData.city_id)
                            .map((item, i) => (
                                <li key={i} onClick={() => handleCityClick(Number(item))}>
                                    <input name='city_id[]' value={selectedCity[i]} type="hidden" />
                                    <div className='wx-box'>
                                        <span>{cityData[item - 1][`cityname_${lang}`]}</span>
                                        <span className='wx-title'>{trans[`${lang}`].city}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </form>
    );
};

export default Search;
