const cityData=[
   
    {
    "city_id": 1,
    "cityname_az": "Bakı",
    "cityname_en": "Baku",
    "cityname_ru": "Баку"
    },
    {
    "city_id": 2,
    "cityname_az": "Sumqayıt",
    "cityname_en": "Sumqayit",
    "cityname_ru": "Сумгаит"
    },
    {
    "city_id": 3,
    "cityname_az": "Xırdalan",
    "cityname_en": "Khirdalan",
    "cityname_ru": "Хырдалан"
    },
    {
    "city_id": 4,
    "cityname_az": "Abşeron",
    "cityname_en": "Absheron",
    "cityname_ru": "Абшерон"
    },
    {
    "city_id": 5,
    "cityname_az": "Gəncə",
    "cityname_en": "Ganja",
    "cityname_ru": "Гянджа"
    },
    {
    "city_id": 6,
    "cityname_az": "Xızı",
    "cityname_en": "Khizi",
    "cityname_ru": "Хызы"
    },
    {
    "city_id": 7,
    "cityname_az": "Ağcabədi",
    "cityname_en": "Aghjabadi",
    "cityname_ru": "Агджабеди"
    },
    {
    "city_id": 8,
    "cityname_az": "Ağdaş",
    "cityname_en": "Aghdash",
    "cityname_ru": "Агдаш"
    },
    {
    "city_id": 9,
    "cityname_az": "Bərdə",
    "cityname_en": "Barda",
    "cityname_ru": "Барда"
    },
    {
    "city_id": 10,
    "cityname_az": "Beyləqan",
    "cityname_en": "Beylagan",
    "cityname_ru": "Бейлаган"
    },
    {
    "city_id": 11,
    "cityname_az": "Biləsuvar",
    "cityname_en": "Bilasuvar",
    "cityname_ru": "Билясувар"
    },
    {
    "city_id": 12,
    "cityname_az": "Göyçay",
    "cityname_en": "Goychay",
    "cityname_ru": "Геокчай"
    },
    {
    "city_id": 13,
    "cityname_az": "Hacıqabul",
    "cityname_en": "Hajigabul",
    "cityname_ru": "Гаджигабул"
    },
    {
    "city_id": 14,
    "cityname_az": "İmişli",
    "cityname_en": "Imishli",
    "cityname_ru": "Имишли"
    },
    {
    "city_id": 15,
    "cityname_az": "Kürdəmir",
    "cityname_en": "Kurdamir",
    "cityname_ru": "Кюрдамир"
    },
    {
    "city_id": 16,
    "cityname_az": "Neftçala",
    "cityname_en": "Neftchala",
    "cityname_ru": "Нефтчала"
    },
    {
    "city_id": 17,
    "cityname_az": "Saatlı",
    "cityname_en": "Saatly",
    "cityname_ru": "Саатлы"
    },
    {
    "city_id": 18,
    "cityname_az": "Sabirabad",
    "cityname_en": "Sabirabad",
    "cityname_ru": "Сабирабад"
    },
    {
    "city_id": 19,
    "cityname_az": "Salyan",
    "cityname_en": "Salyan",
    "cityname_ru": "Сальян"
    },
    {
    "city_id": 20,
    "cityname_az": "Ucar",
    "cityname_en": "Ujar",
    "cityname_ru": "Уджар"
    },
    {
    "city_id": 21,
    "cityname_az": "Yevlax",
    "cityname_en": "Yevlakh",
    "cityname_ru": "Евлах"
    },
    {
    "city_id": 22,
    "cityname_az": "Zərdab",
    "cityname_en": "Zardab",
    "cityname_ru": "Зардаб"
    },
    {
    "city_id": 23,
    "cityname_az": "Mingəçevir",
    "cityname_en": "Mingachevir",
    "cityname_ru": "Мингечевир"
    },
    {
    "city_id": 24,
    "cityname_az": "Şirvan",
    "cityname_en": "Shirvan",
    "cityname_ru": "Ширван"
    },
    {
    "city_id": 25,
    "cityname_az": "Ağsu",
    "cityname_en": "Aghsu",
    "cityname_ru": "Агсу"
    },
    {
    "city_id": 26,
    "cityname_az": "Qobustan",
    "cityname_en": "Gobustan",
    "cityname_ru": "Гобустан"
    },
    {
    "city_id": 27,
    "cityname_az": "İsmayıllı",
    "cityname_en": "Ismailly",
    "cityname_ru": "Исмаиллы"
    },
    {
    "city_id": 28,
    "cityname_az": "Şamaxı",
    "cityname_en": "Shamakhy",
    "cityname_ru": "Шемаха"
    },
    {
    "city_id": 29,
    "cityname_az": "Ağstafa",
    "cityname_en": "Aghstafa",
    "cityname_ru": "Агстафа"
    },
    {
    "city_id": 30,
    "cityname_az": "Daşkəsən",
    "cityname_en": "Dashkasan",
    "cityname_ru": "Дашкесан"
    },
    {
    "city_id": 31,
    "cityname_az": "Gədəbəy",
    "cityname_en": "Gadabay",
    "cityname_ru": "Гедабек"
    },
    {
    "city_id": 32,
    "cityname_az": "Qazax",
    "cityname_en": "Gazakh",
    "cityname_ru": "Газах"
    },
    {
    "city_id": 33,
    "cityname_az": "Göygöl",
    "cityname_en": "Goygol",
    "cityname_ru": "Гёйгёль"
    },
    {
    "city_id": 34,
    "cityname_az": "Goranboy",
    "cityname_en": "Goranboy",
    "cityname_ru": "Геранбой"
    },
    {
    "city_id": 35,
    "cityname_az": "Samux",
    "cityname_en": "Samukh",
    "cityname_ru": "Самух"
    },
    {
    "city_id": 36,
    "cityname_az": "Şəmkir",
    "cityname_en": "Shamkir",
    "cityname_ru": "Шамкир"
    },
    {
    "city_id": 37,
    "cityname_az": "Tovuz",
    "cityname_en": "Tovuz",
    "cityname_ru": "Товуз"
    },
    {
    "city_id": 38,
    "cityname_az": "Naftalan",
    "cityname_en": "Naftalan",
    "cityname_ru": "Нафталан"
    },
    {
    "city_id": 39,
    "cityname_az": "Quba",
    "cityname_en": "Guba",
    "cityname_ru": "Губа"
    },
    {
    "city_id": 40,
    "cityname_az": "Qusar",
    "cityname_en": "Gusar",
    "cityname_ru": "Гусар"
    },
    {
    "city_id": 41,
    "cityname_az": "Xaçmaz",
    "cityname_en": "Khachmaz",
    "cityname_ru": "Хачмаз"
    },
    {
    "city_id": 42,
    "cityname_az": "Şabran",
    "cityname_en": "Shabran",
    "cityname_ru": "Шабран"
    },
    {
    "city_id": 43,
    "cityname_az": "Siyəzən",
    "cityname_en": "Siyazan",
    "cityname_ru": "Сийазань"
    },
    {
    "city_id": 44,
    "cityname_az": "Astara",
    "cityname_en": "Astara",
    "cityname_ru": "Астара"
    },
    {
    "city_id": 45,
    "cityname_az": "Cəlilabad",
    "cityname_en": "Jalilabad",
    "cityname_ru": "Джалилабад"
    },
    {
    "city_id": 46,
    "cityname_az": "Lənkəran",
    "cityname_en": "Lankaran",
    "cityname_ru": "Ленкорань"
    },
    {
    "city_id": 47,
    "cityname_az": "Lerik",
    "cityname_en": "Lerik",
    "cityname_ru": "Лерик"
    },
    {
    "city_id": 48,
    "cityname_az": "Masallı",
    "cityname_en": "Masally",
    "cityname_ru": "Масаллы"
    },
    {
    "city_id": 49,
    "cityname_az": "Yardımlı",
    "cityname_en": "Yardimly",
    "cityname_ru": "Ярдымлы"
    },
    {
    "city_id": 50,
    "cityname_az": "Balakən",
    "cityname_en": "Balakan",
    "cityname_ru": "Балакан"
    },
    {
    "city_id": 51,
    "cityname_az": "Qəbələ",
    "cityname_en": "Gabala",
    "cityname_ru": "Габаля"
    },
    {
    "city_id": 52,
    "cityname_az": "Qax",
    "cityname_en": "Gakh",
    "cityname_ru": "Гах"
    },
    {
    "city_id": 53,
    "cityname_az": "Oğuz",
    "cityname_en": "Oghuz",
    "cityname_ru": "Огуз"
    },
    {
    "city_id": 54,
    "cityname_az": "Şəki",
    "cityname_en": "Shaki",
    "cityname_ru": "Шеки"
    },
    {
    "city_id": 55,
    "cityname_az": "Zaqatala",
    "cityname_en": "Zaqatala",
    "cityname_ru": "Загатала"
    },
    {
    "city_id": 56,
    "cityname_az": "Xocavənd",
    "cityname_en": "Khojavend",
    "cityname_ru": "Ходжавенд"
    },
    {
    "city_id": 57,
    "cityname_az": "Xocalı",
    "cityname_en": "Khojali",
    "cityname_ru": "Ходжалы"
    },
    {
    "city_id": 58,
    "cityname_az": "Şuşa",
    "cityname_en": "Shusha",
    "cityname_ru": "Шуша"
    },
    {
    "city_id": 59,
    "cityname_az": "Laçın",
    "cityname_en": "Lachin",
    "cityname_ru": "Лачин"
    },
    {
    "city_id": 60,
    "cityname_az": "Kəlbəcər",
    "cityname_en": "Kalbadjar",
    "cityname_ru": "Кельбаджар"
    },
    {
    "city_id": 61,
    "cityname_az": "Ağdam",
    "cityname_en": "Agdam",
    "cityname_ru": "Агдам"
    },
    {
    "city_id": 62,
    "cityname_az": "Cəbrayıl",
    "cityname_en": "Jabrayil",
    "cityname_ru": "Джебраил"
    },
    {
    "city_id": 63,
    "cityname_az": "Qubadlı",
    "cityname_en": "Gubadli",
    "cityname_ru": "Губадлы"
    },
    {
    "city_id": 64,
    "cityname_az": "Füzuli",
    "cityname_en": "Fuzuli",
    "cityname_ru": "Фузули"
    },
    {
    "city_id": 65,
    "cityname_az": "Naxcıvan",
    "cityname_en": "Nakhchivan",
    "cityname_ru": "Нахчыван"
    }
   ]


export default cityData