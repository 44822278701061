import React from 'react';
import { createRoot } from 'react-dom/client'; 
import "./css/search.css"
import Approuter from './router/Approuter';
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    
      <Approuter/>
    
  </React.StrictMode>
);






