import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Search from '../component/Search';

const url = document.getElementById('root').getAttribute('data-url');
const Approuter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/${url}`} element={<Search />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Approuter
