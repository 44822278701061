   const villages= [
    {"id":"1","village_az":"Aşağı Güzdək","village_ru":"Ашагы Гюздяк","village_en":"Ashagi Guzdek","Lattitude":"40.480429","Longitude":"49.665596","district_id":"1"},
    {"id":"2","village_az":"Ceyranbatan","village_ru":"Джейранбатан","village_en":"Jeyranbatan","Lattitude":"40.543694","Longitude":"49.667752","district_id":"1"},
    {"id":"3","village_az":"Digah","village_ru":"Дигях","village_en":"Digah","Lattitude":"40.496896","Longitude":"49.87626","district_id":"1"},
    {"id":"4","village_az":"Güzdək","village_ru":"Гюздяк","village_en":"Guzdek","Lattitude":"40.372034","Longitude":"49.679489","district_id":"1"},
    {"id":"5","village_az":"Hökməli","village_ru":"Хокмяли","village_en":"Hokmeli","Lattitude":"40.430116","Longitude":"49.73348","district_id":"1"},
    {"id":"6","village_az":"Mehdiabad","village_ru":"Мехдиабад","village_en":"Mehdiabad","Lattitude":"40.499128","Longitude":"49.854052","district_id":"1"},
    {"id":"7","village_az":"Pereküşkül","village_ru":"Перекюшкюль","village_en":"Perekushkul","Lattitude":"40.48482","Longitude":"49.596147","district_id":"1"},
    {"id":"8","village_az":"Qobu","village_ru":"Гобу","village_en":"Gobu","Lattitude":"40.405011","Longitude":"49.711006","district_id":"1"},
    {"id":"9","village_az":"Saray","village_ru":"Сарай","village_en":"Saray","Lattitude":"40.52757","Longitude":"49.712221","district_id":"1"},
    {"id":"10","village_az":"Fatmayı","village_ru":"Фатмаи","village_en":"Fatmai","Lattitude":"40.529647","Longitude":"49.844766","district_id":"1"},
    {"id":"11","village_az":"Goradil","village_ru":"Горадиль","village_en":"Goradil","Lattitude":"40.549109","Longitude":"49.828002","district_id":"1"},
    {"id":"12","village_az":"Masazır","village_ru":"Масазыр","village_en":"Masazir","Lattitude":"40.476991","Longitude":"49.757144","district_id":"1"},
    {"id":"13","village_az":"Novxanı","village_ru":"Новханы","village_en":"Novkhani","Lattitude":"40.527873","Longitude":"49.783666","district_id":"1"},
    {"id":"14","village_az":"28 may","village_ru":"пос. 28 мая","village_en":"28-May","Lattitude":"40.4848672","Longitude":"49.8446465","district_id":"2"},
    {"id":"15","village_az":"Biləcəri","village_ru":"пос. Биляджари","village_en":"Biladjari","Lattitude":"40.438658","Longitude":"49.810931","district_id":"2"},
    {"id":"16","village_az":"Binəqədi","village_ru":"пос. Бинагяди","village_en":"Binagadi","Lattitude":"40.473622","Longitude":"49.825845","district_id":"2"},
    {"id":"17","village_az":"Çiçək","village_ru":"пос. Чичяк","village_en":"Chichek","Lattitude":"40.43703","Longitude":"49.750731","district_id":"2"},
    {"id":"18","village_az":"Rəsulzadə","village_ru":"пос. Рясулзаде","village_en":"Rasulzade","Lattitude":"40.4336","Longitude":"49.833505","district_id":"2"},
    {"id":"19","village_az":"Sulutəpə","village_ru":"пос. Сулутяпя","village_en":"Sulutepe","Lattitude":"40.429533","Longitude":"49.769939","district_id":"2"},
    {"id":"20","village_az":"Xocəsən","village_ru":"пос. Ходжасан","village_en":"Khodjasan","Lattitude":"40.4112","Longitude":"49.763896","district_id":"2"},
    {"id":"21","village_az":"6-cı mikrorayon","village_ru":"6-ой микрорайон","village_en":"6-th mkr.","Lattitude":"40.419963","Longitude":"49.828717","district_id":"2"},
    {"id":"22","village_az":"7-ci mikrorayon","village_ru":"7-ой микрорайон","village_en":"7-th mkr.","Lattitude":"40.429187","Longitude":"49.853099","district_id":"2"},
    {"id":"23","village_az":"8-ci mikrorayon","village_ru":"8-ой микрорайон","village_en":"8-th mkr.","Lattitude":"40.421969","Longitude":"49.841956","district_id":"2"},
    {"id":"24","village_az":"9-cu mikrorayon","village_ru":"9-ый микрорайон","village_en":"9-th mkr.","Lattitude":"40.4222532","Longitude":"49.8035278","district_id":"2"},
    {"id":"25","village_az":"Xutor","village_ru":"пос. Хутор","village_en":"Khutor","Lattitude":"40.411333","Longitude":"49.826644","district_id":"2"},
    {"id":"26","village_az":"Baş Ələt","village_ru":"Пос. Баш Алят","village_en":"Bash Alyat","Lattitude":"39.975209","Longitude":"49.408738","district_id":"6"},
    {"id":"27","village_az":"Ceyildağ","village_ru":"пос. Джаильдаг","village_en":"Jeyildag","Lattitude":"40.282273","Longitude":"49.280205","district_id":"6"},
    {"id":"28","village_az":"Heybət","village_ru":"пос. Гейбат","village_en":"Heybat","Lattitude":"40.337523","Longitude":"49.775429","district_id":"6"},
    {"id":"29","village_az":"Korgöz","village_ru":"пос. Коргёз","village_en":"Korgoz","Lattitude":"40.305275","Longitude":"49.629917","district_id":"6"},
    {"id":"30","village_az":"Kotal","village_ru":"пос. Котал","village_en":"Kotal","Lattitude":"39.986135","Longitude":"49.431992","district_id":"6"},
    {"id":"31","village_az":"Lökbatan","village_ru":"пос. Локбатан","village_en":"Lokbatan","Lattitude":"40.326954","Longitude":"49.731939","district_id":"6"},
    {"id":"32","village_az":"Müşviqabad","village_ru":"пос. Мушвигабад","village_en":"Mushviqabad","Lattitude":"40.468547","Longitude":"49.623442","district_id":"6"},
    {"id":"33","village_az":"Pirsaat","village_ru":"пос. Пирсаат","village_en":"Pirsaat","Lattitude":"40.048259","Longitude":"49.047904","district_id":"6"},
    {"id":"34","village_az":"Puta","village_ru":"Поселок Пута","village_en":"Puta","Lattitude":"40.296632","Longitude":"49.659493","district_id":"6"},
    {"id":"35","village_az":"Qaradağ","village_ru":"пос. Гарадаг","village_en":"Garadakh","Lattitude":"40.301159","Longitude":"49.706242","district_id":"6"},
    {"id":"36","village_az":"Qarakosa","village_ru":"пос. Гаракоса","village_en":"Garakosa","Lattitude":"39.97063","Longitude":"49.403228","district_id":"6"},
    {"id":"37","village_az":"Qızıldaş","village_ru":"пос. Гызылдаш","village_en":"Kizildash","Lattitude":"40.304918","Longitude":"49.59942","district_id":"6"},
    {"id":"38","village_az":"Qobustan","village_ru":"пос. Гобустан","village_en":"Qobustan","Lattitude":"40.081131","Longitude":"49.409868","district_id":"6"},
    {"id":"39","village_az":"Sahil","village_ru":"пос. Сахиль","village_en":"Sahil","Lattitude":"40.226757","Longitude":"49.572372","district_id":"6"},
    {"id":"40","village_az":"Şıxlar","village_ru":"пос. Шыхлар","village_en":"Shikhlar","Lattitude":"40.021843","Longitude":"49.434648","district_id":"6"},
    {"id":"41","village_az":"Şonqar","village_ru":"пос. Шонгар","village_en":"Shongar","Lattitude":"40.321703","Longitude":"49.600649","district_id":"6"},
    {"id":"42","village_az":"Şubani","village_ru":"пос. Шубани","village_en":"Shubani","Lattitude":"40.366849","Longitude":"49.768576","district_id":"6"},
    {"id":"43","village_az":"Səngəçal","village_ru":"пос. Сангачал","village_en":"Sangachal","Lattitude":"40.17055","Longitude":"49.464456","district_id":"6"},
    {"id":"44","village_az":"Ümid","village_ru":"пос. Умид","village_en":"Umid","Lattitude":"40.202199","Longitude":"49.514571","district_id":"6"},
    {"id":"45","village_az":"Yeni Ələt","village_ru":"пос. Йени Алят","village_en":"Yeni Alyat","Lattitude":"39.954755","Longitude":"49.408944","district_id":"6"},
    {"id":"46","village_az":"Ələt","village_ru":"пос. Алят","village_en":"Alyat","Lattitude":"39.944559","Longitude":"49.405998","district_id":"6"},
    {"id":"47","village_az":"Böyükşor","village_ru":"пос. Боюкшор","village_en":"Boyukshor","Lattitude":"40.432936","Longitude":"49.893989","district_id":"3"},
    {"id":"48","village_az":"Montin","village_ru":"пос. Монтин","village_en":"Montin","Lattitude":"40.408599","Longitude":"49.866272","district_id":"3"},
    {"id":"49","village_az":"1-ci mikrorayon","village_ru":"1-ый микрорайон","village_en":"1-st mkr.","Lattitude":"40.407809","Longitude":"49.808076","district_id":"4"},
    {"id":"50","village_az":"2-ci mikrorayon","village_ru":"2-ой микрорайон","village_en":"2-nd mkr.","Lattitude":"40.41339","Longitude":"49.817875","district_id":"4"},
    {"id":"51","village_az":"3-cü mikrorayon","village_ru":"3-ий микрорайон","village_en":"3-rd mkr.","Lattitude":"40.409197","Longitude":"49.818596","district_id":"4"},
    {"id":"52","village_az":"4-cü mikrorayon","village_ru":"4-ый микрорайон","village_en":"4-th mkr.","Lattitude":"40.416615","Longitude":"49.811469","district_id":"4"},
    {"id":"53","village_az":"5-ci mikrorayon","village_ru":"5-ый микрорайон","village_en":"5-th mkr.","Lattitude":"40.410985","Longitude":"49.81104","district_id":"4"},
    {"id":"54","village_az":"Kubinka","village_ru":"Кубинка","village_en":"Kubinka","Lattitude":"40.382194","Longitude":"49.833992","district_id":"4"},
    {"id":"55","village_az":"Kimyaçılar şəhərciyi","village_ru":"Хим городок","village_en":"Kimyachilar","Lattitude":"40.397325","Longitude":"49.821682","district_id":"4"},
    {"id":"56","village_az":"Papanin","village_ru":"Папанин","village_en":"Papanin","Lattitude":"40.400967","Longitude":"49.821552","district_id":"4"},
    {"id":"57","village_az":"8-ci kilometr","village_ru":"8-ой километр","village_en":"8-th km","Lattitude":"40.409928","Longitude":"49.943534","district_id":"5"},
    {"id":"58","village_az":"Keşlə","village_ru":"пос.Кешля","village_en":"Keshlya","Lattitude":"40.394575","Longitude":"49.896758","district_id":"5"},
    {"id":"59","village_az":"UPD","village_ru":"UPD","village_en":"UPD","Lattitude":"40.387694","Longitude":"49.907497","district_id":"5"},
    {"id":"60","village_az":"Bakıxanov","village_ru":"Бакиханов","village_en":"Bakikhanov","Lattitude":"40.426619","Longitude":"49.953761","district_id":"7"},
    {"id":"61","village_az":"Balaxanı","village_ru":"Балаханы","village_en":"Balakhani","Lattitude":"40.462232","Longitude":"49.92037","district_id":"7"},
    {"id":"62","village_az":"Bilgəh","village_ru":"Билгя","village_en":"Bilgah","Lattitude":"40.577973","Longitude":"50.041923","district_id":"7"},
    {"id":"63","village_az":"Kürdəxanı","village_ru":"Курдахани","village_en":"Kurdkhani","Lattitude":"40.547087","Longitude":"49.918642","district_id":"7"},
    {"id":"64","village_az":"Nardaran","village_ru":"пос. Нардаран","village_en":"Nardaran","Lattitude":"40.575886","Longitude":"49.987972","district_id":"7"},
    {"id":"65","village_az":"Maştağa","village_ru":"Маштага","village_en":"Mashtaga","Lattitude":"40.53393","Longitude":"49.996518","district_id":"7"},
    {"id":"66","village_az":"Pirşağı","village_ru":"пос. Пиршаги","village_en":"Pirshgi","Lattitude":"40.563565","Longitude":"49.890362","district_id":"7"},
    {"id":"67","village_az":"Ramana","village_ru":"пос. Рамана","village_en":"Ramana","Lattitude":"40.457515","Longitude":"49.983556","district_id":"7"},
    {"id":"68","village_az":"Sabunçu","village_ru":"пос. Сабунчу","village_en":"Sabunchu","Lattitude":"40.419084","Longitude":"49.962911","district_id":"7"},
    {"id":"69","village_az":"Savalan","village_ru":"пос. Савалан","village_en":"Savalan","Lattitude":"40.512385","Longitude":"49.970938","district_id":"7"},
    {"id":"70","village_az":"Yeni Ramana","village_ru":"Йени Рамана","village_en":"Yeni Ramana","Lattitude":"40.442965","Longitude":"49.98057","district_id":"7"},
    {"id":"71","village_az":"Zabrat","village_ru":"пос. Забрат","village_en":"Zabrat","Lattitude":"40.484835","Longitude":"49.945024","district_id":"7"},
    {"id":"72","village_az":"Məhəmmədli","village_ru":"Мехеммедли","village_en":"Mahammadli","Lattitude":"40.505667","Longitude":"49.90657","district_id":"7"},
    {"id":"73","village_az":"Vişnyovka","village_ru":"Вишнйовка","village_en":"Vishnyovka","Lattitude":"40.5447016","Longitude":"50.0655199","district_id":"7"},
    {"id":"74","village_az":"Bahar","village_ru":"пос. Бахар","village_en":"Bakhar","Lattitude":"40.369999","Longitude":"50.029172","district_id":"12"},
    {"id":"75","village_az":"Bülbülə","village_ru":"пос. Бюльбуля","village_en":"Byulbulya","Lattitude":"40.436781","Longitude":"49.968428","district_id":"12"},
    {"id":"76","village_az":"Dədə Qorqud","village_ru":"пос. Дядя Горгуд","village_en":"Dede Gorgud","Lattitude":"40.387763","Longitude":"50.027026","district_id":"12"},
    {"id":"77","village_az":"Hövsan","village_ru":"пос. Говсан","village_en":"Govsan","Lattitude":"40.37757","Longitude":"50.093014","district_id":"12"},
    {"id":"78","village_az":"Qaraçuxur","village_ru":"пос. Гарачухур","village_en":"Garachukur","Lattitude":"40.40291","Longitude":"49.979593","district_id":"12"},
    {"id":"79","village_az":"Suraxanı","village_ru":"пос. Сураханы","village_en":"Surakhani","Lattitude":"40.420739","Longitude":"50.004265","district_id":"12"},
    {"id":"80","village_az":"Yeni Günəşli","village_ru":"пос. Йени Гюняшли","village_en":"Yeni Guneshli","Lattitude":"40.380892","Longitude":"49.979832","district_id":"12"},
    {"id":"81","village_az":"Yeni Suraxanı","village_ru":"пос. Йени Сураханы","village_en":"Yeni Surakhani","Lattitude":"40.425818","Longitude":"50.038728","district_id":"12"},
    {"id":"82","village_az":"Zığ","village_ru":"пос. Зыг","village_en":"Zig","Lattitude":"40.345544","Longitude":"49.978956","district_id":"12"},
    {"id":"83","village_az":"Əmircan","village_ru":"пос. Амирджан","village_en":"Amirdjan","Lattitude":"40.426491","Longitude":"49.987216","district_id":"12"},
    {"id":"84","village_az":"20-ci sahə","village_ru":"20-ый","village_en":"20-th","Lattitude":"40.329796","Longitude":"49.818836","district_id":"8"},
    {"id":"85","village_az":"Badamdar","village_ru":"пос. Бадамдар","village_en":"Badamdar","Lattitude":"40.332661","Longitude":"49.806262","district_id":"8"},
    {"id":"86","village_az":"Bayıl","village_ru":"пос. Байыл","village_en":"Bayil","Lattitude":"40.349802","Longitude":"49.831578","district_id":"8"},
    {"id":"87","village_az":"Bibi Heybət","village_ru":"пос. Биби Хейбят","village_en":"Bibi Heybat","Lattitude":"40.305458","Longitude":"49.823148","district_id":"8"},
    {"id":"88","village_az":"İçərişəhər","village_ru":"Ичеришехер","village_en":"Icherisheher","Lattitude":"40.366222","Longitude":"49.834948","district_id":"8"},
    {"id":"89","village_az":"Ağ şəhər","village_ru":"Белый город","village_en":"White City","Lattitude":"40.383244","Longitude":"49.885664","district_id":"9"},
    {"id":"90","village_az":"H.Aslanov","village_ru":"пос. А.Асланов","village_en":"H.Aslanov","Lattitude":"40.372886","Longitude":"49.954456","district_id":"9"},
    {"id":"91","village_az":"Köhnə Günəşli","village_ru":"пос. Старый Гуняшли","village_en":"Kohne Guneshli","Lattitude":"40.375354","Longitude":"49.96436","district_id":"9"},
    {"id":"92","village_az":"kənd Əhmədli","village_ru":"старый Ахмедли","village_en":"Old Akhmedli","Lattitude":"40.38136","Longitude":"49.952105","district_id":"9"},
    {"id":"93","village_az":"Qara şəhər","village_ru":"Черный город","village_en":"Gara Sheher","Lattitude":"40.382112","Longitude":"49.893744","district_id":"9"},
    {"id":"94","village_az":"Əhmədli","village_ru":"Ахмедли","village_en":"Akhmedli","Lattitude":"40.38235","Longitude":"49.949172","district_id":"9"},
    {"id":"95","village_az":"NZS","village_ru":"НЗС","village_en":"NZS","Lattitude":"40.386357","Longitude":"49.91411","district_id":"9"},
    {"id":"96","village_az":"Binə","village_ru":"пос. Биня","village_en":"Bina","Lattitude":"40.457585","Longitude":"50.096669","district_id":"10"},
    {"id":"97","village_az":"Buzovna","village_ru":"Бузовна","village_en":"Buzovna","Lattitude":"40.515361","Longitude":"50.100428","district_id":"10"},
    {"id":"98","village_az":"Dübəndi","village_ru":"Дубянди","village_en":"Dubandi","Lattitude":"40.438047","Longitude":"50.211407","district_id":"10"},
    {"id":"99","village_az":"Gürgən","village_ru":"Гюргян","village_en":"Gurgan","Lattitude":"40.397924","Longitude":"50.335278","district_id":"10"},
    {"id":"100","village_az":"Mərdəkan","village_ru":"Мярдякан","village_en":"Mardakan","Lattitude":"40.485647","Longitude":"50.147865","district_id":"10"},
    {"id":"101","village_az":"Qala","village_ru":"Гала","village_en":"Gala","Lattitude":"40.451167","Longitude":"50.155763","district_id":"10"},
    {"id":"102","village_az":"Şağan","village_ru":"Шаган","village_en":"Shagan","Lattitude":"40.490171","Longitude":"50.119285","district_id":"10"},
    {"id":"103","village_az":"Şimal qres","village_ru":"Шимал грес","village_en":"Shimal","Lattitude":"40.49936","Longitude":"50.210566","district_id":"10"},
    {"id":"104","village_az":"Şüvəlan","village_ru":"Шювалан","village_en":"Shuvalan","Lattitude":"40.486989","Longitude":"50.209237","district_id":"10"},
    {"id":"105","village_az":"Türkan","village_ru":"Туркян","village_en":"Turkan","Lattitude":"40.365299","Longitude":"50.219101","district_id":"10"},
    {"id":"106","village_az":"Zaqulba","village_ru":"Загульба","village_en":"Zagulba","Lattitude":"40.535544","Longitude":"50.099161","district_id":"10"},
    {"id":"107","village_az":"Zirə","village_ru":"Зиря","village_en":"Zirya","Lattitude":"40.36757","Longitude":"50.286117","district_id":"10"},
    {"id":"108","village_az":"Alatava","village_ru":"Алатава","village_en":"Alatava","Lattitude":"40.409904","Longitude":"49.800743","district_id":"11"},
    {"id":"109","village_az":"Yasamal","village_ru":"пос. Ясамал","village_en":"Yasamal","Lattitude":"40.375576","Longitude":"49.809823","district_id":"11"},
    {"id":"110","village_az":"Yeni Yasamal","village_ru":"пос. Йени Ясамал","village_en":"Yeni Yasamal","Lattitude":"40.393032","Longitude":"49.795833","district_id":"11"},
    {"id":"111","village_az":"Şamaxinka","village_ru":"Шамахинка","village_en":"Shamakhinka","Lattitude":"40.412483","Longitude":"49.805751","district_id":"11"}
    ]
    export default villages