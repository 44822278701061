
const translations = {

    "az": {
        "numberofrooms": "Otaq sayı",
        "anynumberofrooms": "Fərq etməz",
        "room": " otaqlı",
        "rooms": " otaqlı",
        "roomsmore": " otaqlı +",
        "price": "Qiymət",
        "azn": "AZN",
        "min": "min.",
        "max": "maks.",
        "city": "Şəhər",
        "reset": "Sıfırla",
        "field": "Sahə, m²",
        "area": "Sahə, sot",
        "searchmore": "Ətraflı Axtarış",
        "districtandvilliage": "Rayon və Qəsəbələr",
        "metrostations": "Metrostansiyalar",
        "landmarks": "Nişangahlar",
        "landarea": "Torpağın sahəsi, sot",
        "kupcha": "Çıxarış var",
        "adsnumber": "Elanın nömrəsi",
        "normalrepaired": "Orta təmirli",
        "goodrepaired": "Əla təmirli",
        "norepaired": "Təmirsiz",
        "typeofbuilding": "Tikilinin növü",
        "businesscenter": "Biznes Mərkəzi",
        "homebuilding": "Ev/Mənzil",
        "villa": "Villa",
        "floor": "Mərtəbə",
        "nofirstfloor": "1-ci olmasın",
        "notopfloor": "Ən üst olmasın",
        "onlyfirstfloor": "Yalnız ən üst",
        "dosearch": "Axtar",
        "barter": "Barter",
        "internalcredit": "Daxili kredit",
        "credit": "Kredit",
        "usefullmortgage": "İpotekaya yararlı",
        "readymortgage": "Hazır İpoteka",
        "items": "Əşyalı",
        "gas": "Qaz",
        "waterbarrel": "Su çəni",
        "combi": "Kombi",
        "viewsea": "Dəniz mənzərəli",
        "pool": "Basseyn",
        "nearsea": "Dənizə yaxın",
        "electricline": "İşıq xətti",
        "waterline": "Su xətti",
        "gasline": "Qaz xətti",
        "hide": "Gizlət",
        "showannouns": "Elanı göstər",
        "show" : "Göstər",
        "bakucity": "Bakı şəhəri",
        "addsearch": "Axtarışa əlavə et",
        "resetlocation": "Yerləşməni sıfırla",
        "allchecked": "Hamısını seç",
        "districts": "Rayonlar",
        "villiages": "Qəsəbələr",
        "metros": "Metrostansiyalar",
        "district": "Rayon",
        "villiage": "Qəsəbə",
        "metro": "Metrostansiya",
        "landmark": "Nişangah",
        "cityofname": "Şəhərin adı",
        "location": "Yerləşmə",
        "locationtitle": "Məkanı daxil edin",
        "coworking": "Co-working",
        "notfinedresult":"Nəticə tapılmadı",
        "minimum":"Minimum",
        "maximum":"Maksimum",
    },

    "ru": {
        "numberofrooms": "Комнат",
        "anynumberofrooms": "Любая комнатность",
        "room": " kомн",
        "rooms": " kомн",
        "roomsmore": " и более комнат",
        "price": "Цена",
        "azn": "АЗН",
        "min": "мин.",
        "max": "макс.",
        "city": "Город",
        "reset": "Сбросить",
        "field": "Площадь, м²",
        "area": "Площадь, сот",
        "searchmore": "Подробный поиск",
        "districtandvilliage": "Район и поселки",
        "metrostations": "Станции метро",
        "landmarks": "Ориентиры",
        "landarea": "Площадь участка, сот",
        "kupcha": "Есть выход",
        "adsnumber": "Номер объявления",
        "normalrepaired": "Средний ремонт",
        "goodrepaired": "Хороший ремонт",
        "norepaired": "Без ремонта",
        "typeofbuilding": "Тип здания",
        "businesscenter": "Бизнес-центр",
        "homebuilding": "Дом/Квартира",
        "villa": "Вилла",
        "floor": "Этаж",
        "nofirstfloor": "Не первый этаж",
        "notopfloor": "Не последний этаж",
        "onlyfirstfloor": "Только первый этаж",
        "dosearch": "Поиск",
        "barter": "Бартер",
        "internalcredit": "Внутренний кредит",
        "credit": "Кредит",
        "usefullmortgage": "Ипотека выгодна",
        "readymortgage": "Готовая ипотека",
        "items": "Мебель",
        "gas": "Газ",
        "waterbarrel": "Водный танк",
        "combi": "Комби",
        "viewsea": "Вид на море",
        "pool": "Бассейн",
        "nearsea": "Близко к морю",
        "electricline": "Линия электропередач",
        "waterline": "Линия водоснабжения",
        "gasline": "Газовая линия",
        "hide": "Скрыть",
        "showannouns": "Показать объявление",
        "show" : "Показывать",
        "bakucity": "Город Баку",
        "addsearch": "Добавить поиск",
        "resetlocation": "Сбросить местоположение",
        "allchecked": "Выбрать все",
        "districts": "Районы",
        "villiages": "Поселки",
        "metros": "Станции метро",
        "district": "Район",
        "villiage": "Поселок",
        "metro": "Станция метро",
        "landmark": "Ориентир",
        "cityofname": "Название города",
        "location": "Местоположение",
        "locationtitle": "Введите местоположение",
        "coworking": "Совместная работа",
        "notfinedresult": "Результаты не найдены",
        "minimum":"Минимум",
        "maximum":"Максимум",
    },

    "en": {
        "numberofrooms": "Any room",
        "anynumberofrooms": "Any room",
        "room": " room",
        "rooms": " rooms",
        "roomsmore": "rooms and more",
        "price": "Price",
        "azn": "AZN",
        "min": "min.",
        "max": "max.",
        "city": "City",
        "reset": "Reset",
        "field": "Area, m²",
        "area": "Area, ares",
        "searchmore": "Advanced search",
        "districtandvilliage": "Districts and villages",
        "metrostations": "Metro stations",
        "landmarks": "Landmarks",
        "landarea": "Land Area, ares",
        "kupcha": "Title deed",
        "adsnumber": "Ad Number",
        "normalrepaired": "Average repair",
        "goodrepaired": "Good repair",
        "norepaired": "No repair",
        "typeofbuilding": "Building Type",
        "businesscenter": "Business Center",
        "homebuilding": "House/Apartment",
        "villa": "Villa",
        "floor": "Floor",
        "nofirstfloor": "Not first floor",
        "notopfloor": "Not top floor",
        "onlyfirstfloor": "Only first floor",
        "dosearch": "Search",
        "barter": "Barter",
        "internalcredit": "Internal credit",
        "credit": "Credit",
        "usefullmortgage": "Useful mortgage",
        "readymortgage": "Ready mortgage",
        "items": "Furnished",
        "gas": "Gas",
        "waterbarrel": "Water barrel",
        "combi": "Combi",
        "viewsea": "Sea view",
        "pool": "Swimming pool",
        "nearsea": "Near sea",
        "electricline": "Electric line",
        "waterline": "Water line",
        "gasline": "Gas line",
        "hide": "Hide",
        "showannouns": "Show Ad",
        "show" : "Show",
        "bakucity": "Baku city",
        "addsearch": "Add search",
        "resetlocation": "Reset location",
        "allchecked": "Select all",
        "districts": "Districts",
        "villiages": "Villages",
        "metros": "Metro stations",
        "district": "District",
        "villiage": "Village",
        "metro": "Metro station",
        "landmark": "Landmark",
        "cityofname": "City name",
        "location": "Location",
        "locationtitle": "Enter location",
        "coworking": "Co-working",
        "notfinedresult": "No results found",
        "minimum":"Minimum",
        "maximum":"Maximum",
    }
    
    
}

export default translations;