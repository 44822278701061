const districtData=[  

    {
    "district_id": 1,
    "districtname_az": "Abşeron",
    "districtname_en": "Absheron",
    "districtname_ru": "Абшерон"
    },
    {
    "district_id": 2,
    "districtname_az": "Binəqədi",
    "districtname_en": "Binagadi",
    "districtname_ru": "Бинагади"
    },
    {
    "district_id": 3,
    "districtname_az": "Nərimanov",
    "districtname_en": "Narimanov",
    "districtname_ru": "Нариманов"
    },
    {
    "district_id": 4,
    "districtname_az": "Nəsimi",
    "districtname_en": "Nasimi",
    "districtname_ru": "Насими"
    },
    {
    "district_id": 5,
    "districtname_az": "Nizami",
    "districtname_en": "Nizami",
    "districtname_ru": "Низами"
    },
    {
    "district_id": 6,
    "districtname_az": "Qaradağ",
    "districtname_en": "Karadag",
    "districtname_ru": "Карадаг"
    },
    {
    "district_id": 7,
    "districtname_az": "Sabunçu",
    "districtname_en": "Sabunchu",
    "districtname_ru": "Сабунчу"
    },
    {
    "district_id": 8,
    "districtname_az": "Səbail",
    "districtname_en": "Sabail",
    "districtname_ru": "Сабаил"
    },
    {
    "district_id": 9,
    "districtname_az": "Xətai",
    "districtname_en": "Khatai",
    "districtname_ru": "Хатаи"
    },
    {
    "district_id": 10,
    "districtname_az": "Xəzər",
    "districtname_en": "Khazar",
    "districtname_ru": "Хазар"
    },
    {
    "district_id": 11,
    "districtname_az": "Yasamal",
    "districtname_en": "Yasamal",
    "districtname_ru": "Ясамал"
    },
    {
    "district_id": 12,
    "districtname_az": "Suraxanı",
    "districtname_en": "Surakhani",
    "districtname_ru": "Сураханы"
    },
    {
    "district_id": 13,
    "districtname_az": "Pirallahı",
    "districtname_en": "Piralllahi",
    "districtname_ru": "Пираллахи"
    }
]
export default districtData