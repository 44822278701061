const apartment = [
    {
        id:0 ,
        "apartmentName_az":"Mənzil",
        "apartmentName_en":"Apartment",
        "apartmentName_ru":"Квартиру",
    },
    {
        id: 1,
        "apartmentName_az":"Yeni tikili",
        "apartmentName_en":"New building",
        "apartmentName_ru":"Новостройку",
    },
    {
        id: 2,
        "apartmentName_az":"Köhnə tikili",
        "apartmentName_en":"Old building",
        "apartmentName_ru":"Старое здание",
    },
    {
        id:3,
        "apartmentName_az":"Həyət evi/Villa",
        "apartmentName_en":"House/Villa",
        "apartmentName_ru":"Дом/Bилла",
    },
    {
        id:9,
        "apartmentName_az":"Bağ evi",
        "apartmentName_en":"Garden house",
        "apartmentName_ru":"Дача",
    },
    {
        id:4,
        "apartmentName_az":"Ofis",
        "apartmentName_en":"Office",
        "apartmentName_ru":"Офис",
    },
    {
        id:5,
        "apartmentName_az":"Torpaq",
        "apartmentName_en":"Land",
        "apartmentName_ru":"Участок",
    },
    {
        id:6,
        "apartmentName_az":"Qaraj",
        "apartmentName_en":"Garage",
        "apartmentName_ru":"Гараж",
    },
    {
        id:7,
        "apartmentName_az":"Obyekt",
        "apartmentName_en":"Commercial",
        "apartmentName_ru":"Объект",
    },
    {
        id:8,
        "apartmentName_az":"Hazır biznes",
        "apartmentName_en":"Ready business",
        "apartmentName_ru":"Готовый бизнес",
    }
]
export default apartment