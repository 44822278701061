const metros=[
    {"id":"1","metro_az":"20 Yanvar","metro_ru":"м. 20 Января","metro_en":"20 January","Lattitude":"40.4044275","Longitude":"49.8071656","district_id":"4"},
    {"id":"2","metro_az":"28 May","metro_ru":"м. 28 Мая","metro_en":"28 May","Lattitude":"40.3791503","Longitude":"49.847911","district_id":"4"},
    {"id":"3","metro_az":"8 Noyabr","metro_ru":"м. 8 Ноябрь","metro_en":"8 November","Lattitude":"40.4016281","Longitude":"49.8194963","district_id":"4"},
    {"id":"4","metro_az":"Avtovağzal","metro_ru":"м. Автовокзал","metro_en":"Avtovakzal","Lattitude":"40.4214357","Longitude":"49.7928393","district_id":"2"},
    {"id":"5","metro_az":"Azadlıq","metro_ru":"м. Азадлыг","metro_en":"Azadliq","Lattitude":"40.4255353","Longitude":"49.840369","district_id":"2"},
    {"id":"6","metro_az":"Bakmil","metro_ru":"м. Бакмиль","metro_en":"Bakmil","Lattitude":"40.4140941","Longitude":"49.8770734","district_id":"3"},
    {"id":"7","metro_az":"Cəfər Cabbarlı","metro_ru":"м. Джафар Джаббарлы","metro_en":"Jafar Jabbarli","Lattitude":"40.3795581","Longitude":"49.8470104","district_id":"4"},
    {"id":"8","metro_az":"Dərnəgül","metro_ru":"м. Дярнягюль","metro_en":"Darnagul","Lattitude":"40.4248251","Longitude":"49.8593953","district_id":"2"},
    {"id":"9","metro_az":"Elmlər akademiyası","metro_ru":"м. Эльмляр Академиясы","metro_en":"Elmler Akademiyasi","Lattitude":"40.439331","Longitude":"49.7887402","district_id":"11"},
    {"id":"10","metro_az":"Gənclik","metro_ru":"м. Гянджлик","metro_en":"Gandjlik","Lattitude":"40.3997269","Longitude":"49.851017","district_id":"3"},
    {"id":"11","metro_az":"Həzi Aslanov","metro_ru":"м. Ази Асланова","metro_en":"Azi Aslanov","Lattitude":"40.3756728","Longitude":"49.9517954","district_id":"9"},
    {"id":"12","metro_az":"İçərişəhər","metro_ru":"м. Ичяришяхяр","metro_en":"Icherisheher","Lattitude":"40.3658601","Longitude":"49.8251956","district_id":"8"},
    {"id":"13","metro_az":"İnşaatçılar","metro_ru":"Иншаатчылар","metro_en":"Inshaatchilar","Lattitude":"40.3914392","Longitude":"49.8024437","district_id":"11"},
    {"id":"14","metro_az":"Koroğlu","metro_ru":"м. Короглу","metro_en":"Koroglu","Lattitude":"40.4216151","Longitude":"49.9146403","district_id":"5"},
    {"id":"15","metro_az":"Memar Əcəmi","metro_ru":"м. Мемар Аджеми","metro_en":"Memar Ajami","Lattitude":"40.4098956","Longitude":"49.8140414","district_id":"4"},
    {"id":"16","metro_az":"Neftçilər","metro_ru":"м. Нефтчиляр","metro_en":"Neftchiler","Lattitude":"40.4101617","Longitude":"49.9411239","district_id":"5"},
    {"id":"17","metro_az":"Nizami","metro_ru":"м. Низами","metro_en":"Nizami","Lattitude":"40.3803291","Longitude":"49.8291743","district_id":"11"},
    {"id":"18","metro_az":"Nəriman Nərimanov","metro_ru":"м. Нариман Нариманов","metro_en":"Nariman Narimanov","Lattitude":"40.4047697","Longitude":"49.8744628","district_id":"3"},
    {"id":"19","metro_az":"Nəsimi","metro_ru":"м. Насими","metro_en":"Nasimi","Lattitude":"40.424396","Longitude":"49.825022","district_id":"2"},
    {"id":"20","metro_az":"Qara Qarayev","metro_ru":"м. Гара Гараева","metro_en":"Kara Karaev","Lattitude":"40.4177463","Longitude":"49.9327822","district_id":"5"},
    {"id":"21","metro_az":"Sahil","metro_ru":"м. Сахил","metro_en":"Sahil","Lattitude":"40.3714881","Longitude":"49.8438254","district_id":"8"},
    {"id":"22","metro_az":"Ulduz","metro_ru":"м. Улдуз","metro_en":"Ulduz","Lattitude":"40.4152001","Longitude":"49.8906543","district_id":"3"},
    {"id":"23","metro_az":"Xalqlar dostluğu","metro_ru":"м. Халглар Достлугу","metro_en":"Khalglar Dostlugu","Lattitude":"40.3968585","Longitude":"49.9515887","district_id":"5"},
    {"id":"24","metro_az":"Xətai","metro_ru":"м. Хетаи","metro_en":"Khatai","Lattitude":"40.3830901","Longitude":"49.8697653","district_id":"9"},
    {"id":"25","metro_az":"Əhmədli","metro_ru":"м. Ахмедли","metro_en":"Akhmedli","Lattitude":"40.3841354","Longitude":"49.9592412","district_id":"9"}
    ]
    export default metros